<template>
  <div class="show_report">
    <div class="container-fluid px-0">
      <div class="row justify-content-center">
        <div class="col-md-10" v-for="item in 10" :key="item.id">
          <div class="custom_card desc_card">
            <div class="desc_text">
              <h4>اسم الشركة</h4>
              <p>التقرير</p>
            </div>
            <div class="image">
              <img src="@/assets/images/icons/inflcosmall.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
