<template>
  <div class="create_wrapper">
    <Breadcrumb :items="items" />
    <div class="custom_card">
      <!-- START:: CARD TITLE -->
      <div class="card-header">
        <h4 class="card-title">{{ $t("CRUD.Create.main_title") }}</h4>
      </div>
      <!-- END:: CARD TITLE -->

      <!-- START:: CREATE FORM WRAPPER -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- START:: INPUT WRAPPER -->
            <uplode-image-2 @inputChanged="uplodeImg_1"></uplode-image-2>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: TITLE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="name_input"
                  v-model.trim="data.name"
                />
                <label for="name_input" class="form-label">{{
                  $t("customers.data.name")
                }}</label>
              </div>
              <!-- END:: TITLE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-6 py-0">
              <!-- START:: PHONE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="code_input"
                  v-model.trim="data.phone"
                />
                <label for="code_input" class="form-label">{{
                  $t("customers.data.phone")
                }}</label>
              </div>
              <!-- END:: PHONE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-12 py-0">
              <!-- START:: TITLE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="email"
                  class="form-control"
                  id="email_input"
                  v-model.trim="data.email"
                />
                <label for="email_input" class="form-label">{{
                  $t("customers.data.email")
                }}</label>
              </div>
              <!-- END:: TITLE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <!-- START:: BUTTON -->
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("Forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
          <!-- END:: BUTTON -->
        </div>
      </form>
      <!-- END:: CREATE FORM WRAPPER -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // START:: BREADCRUMBS ITEMS
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("customers.title"),
          disabled: false,
          href: "/customers",
        },
        {
          text: this.$t("addNew"),
          disabled: true,
          href: "",
        },
      ],
      // END:: BREADCRUMBS ITEMS

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: CREATE DATA
      data: {
        image: null,
        name: null,
        phone: null,
        email: null,
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.data.image = obj;
    },
    // END:: Uplode Avatar

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.image?.img_src) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("customers.validation.image"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.name) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("customers.validation.name"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.phone) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("customers.validation.phone"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.email) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("customers.validation.email"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          let submit_data = new FormData();
          submit_data.append("image", this.data.image.img_file);
          submit_data.append("name", this.data.name);
          submit_data.append("phone", this.data.phone);
          submit_data.append("email", this.data.email);

          this.$axios({
            method: "POST",
            url: `client/customer`,
            data: submit_data,
          })
            .then(() => {
              this.btnIsLoading = false;
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("CRUD.Create.added"),
                position: "topRight",
              });
              this.$router.push({path: "/customers"});
            })
            .catch((err) => {
              this.btnIsLoading = false;
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
            });
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
};
</script>
