<template>
  <div class="profile_wrapper">
    <!-- START:: PROFILE HEADER -->
    <div class="profile_header_wrapper custom_card">
      <!-- START:: PARTICLES SECTION -->
      <vue-particles
        color="#36be6c"
        :particleOpacity="0.7"
        :particlesNumber="300"
        shapeType="circle"
        :particleSize="4"
        linesColor="#36be6c"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.3"
        :linesDistance="100"
        :moveSpeed="4"
        :hoverEffect="true"
        :clickEffect="true"
        hoverMode="repulse"
        clickMode="push"
      >
      </vue-particles>
      <!-- END:: PARTICLES SECTION -->

      <!-- START:: PROFILE HEADER DATA SECTION -->
      <div class="profile_header_data">
        <!-- START:: AVATAR WRAPPER -->
        <div class="avatar_wrapper">
          <img
            src="../../../assets/images/people/profile.jpg"
            width="100"
            height="100"
            alt="Avatar"
          />
        </div>
        <!-- END:: AVATAR WRAPPER -->

        <!-- START:: EDIT ROUTE WRAPPER -->
        <div class="edit_route_wrapper">
          <router-link to="/">
            <i class="fal fa-edit"></i>
          </router-link>
        </div>
        <!-- END:: EDIT ROUTE WRAPPER -->
      </div>
      <!-- END:: PROFILE HEADER DATA SECTION -->
    </div>
    <!-- END:: PROFILE HEADER -->

    <div class="container-fluid px-0">
      <div class="row">
        <!-- START:: PROFILE TABS -->
        <div class="col-lg-8">
          <Tabs :tabsContent="tabsContent">
            <template v-slot:personal_data>
              <div class="personal_data_wrapper">
                <div class="row">
                  <!-- START:: FULL NAME INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.fullName")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.fullName"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: FULL NAME INPUT -->

                  <!-- START:: PHONE INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.phoneNumber")
                      }}</label>
                      <input
                        type="tel"
                        class="form-control"
                        v-model.trim="profileData.phone"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: PHONE INPUT -->

                  <!-- START:: EMAIL INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.email")
                      }}</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model.trim="profileData.email"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: EMAIL INPUT -->

                  <!-- START:: WALLET INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.wallet")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.wallet"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: WALLET INPUT -->

                  <!-- START:: ADD DATE INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.addDate")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.date"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: ADD DATE INPUT -->

                  <!-- START:: ADD TIME INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.addTime")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.time"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: ADD TIME INPUT -->

                  <!-- START:: COUNTRY INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.country")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.country"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: COUNTRY INPUT -->

                  <!-- START:: CITY INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.city")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.city"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: CITY INPUT -->

                  <!-- START:: ACTIVATION STATUS INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.activationStatus")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.activationStatus"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: ACTIVATION STATUS INPUT -->

                  <!-- START:: BLOCK STATUS INPUT -->
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.blockStatus")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model.trim="profileData.blockStatus"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- END:: BLOCK STATUS INPUT -->

                  <!-- START:: BLOCK REASON INPUT -->
                  <div class="col-12">
                    <div class="input_wrapper top_label my-2">
                      <label class="form-label">{{
                        $t("Forms.Placeholders.blockReason")
                      }}</label>
                      <textarea
                        type="text"
                        class="form-control"
                        rows="7"
                        v-model.trim="profileData.blockReason"
                        readonly
                      ></textarea>
                      <!-- <input type="text" class="form-control" v-model.trim="profileData.blockReason" readonly/> -->
                    </div>
                  </div>
                  <!-- END:: BLOCK REASON INPUT -->
                </div>
              </div>
            </template>

            <template v-slot:wallet>
              <div class="wallet_data_wrapper">
                <h3>Wallet Tab</h3>
              </div>
            </template>
          </Tabs>
        </div>
        <!-- END:: PROFILE TABS -->

        <!-- START:: SIDE CARDS COLUMN -->
        <div class="col-lg-4">
          <!-- START:: WALLET CARD -->
          <div class="col-12 custom_card">
            <div class="wallet_card_wrappe p-3">
              <!-- START:: CARD TITLE -->
              <div class="card-header">
                <h4 class="card-title">{{ $t("Titles.wallet") }}</h4>
              </div>
              <!-- END:: CARD TITLE -->

              <!-- START:: DEBIT BALANCE INPUT -->
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("Forms.Placeholders.debitBalance")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="walletData.debitBalance"
                  readonly
                />
              </div>
              <!-- END:: DEBIT BALANCE INPUT -->

              <!-- START:: WALLET AMOUNT INPUT -->
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("Forms.Placeholders.wallet")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="walletData.walletAmount"
                  readonly
                />
              </div>
              <!-- END:: WALLET AMOUNT INPUT -->
            </div>
          </div>
          <!-- END:: WALLET CARD -->

          <!-- START:: RATING CARD -->
          <div class="col-12 custom_card">
            <div class="wallet_card_wrappe p-3">
              <!-- START:: CARD TITLE -->
              <div class="card-header">
                <h4 class="card-title">{{ $t("Titles.rating") }}</h4>
              </div>
              <!-- END:: CARD TITLE -->

              <!-- START:: RATING STARS -->
              <RatingStars
                class="text-center fs-3"
                dir="ltr"
                :rate="rateData.rate"
              />
              <!-- END:: RATING STARS -->

              <!-- START:: RATE DETAILS -->
              <div class="rate_details text-center">
                <p class="mb-2">{{ rateData.rate }} / 5</p>
                <p>قام {{ rateData.ratesCount }} من الأشخاص بتقييمك</p>
              </div>
              <!-- END:: RATE DETAILS -->
            </div>
          </div>
          <!-- END:: RATING CARD -->
        </div>
        <!-- END:: SIDE CARDS COLUMN -->
      </div>
    </div>
  </div>
</template>

<script>
// START:: IMPORTING TABS
import Tabs from "../../../components/Global/Tabs.vue";
// END:: IMPORTING TABS

// START:: IMPORTING RATING STARS
import RatingStars from "../../../components/ui/RatingStars.vue";
// END:: IMPORTING RATING STARS

export default {
  name: "ProfileView",

  components: {
    Tabs,
    RatingStars,
  },

  data() {
    return {
      // START:: TABS DATA
      tabsContent: [
        {
          tab: "Personal Data",
          key_name: "personal_data",
        },
        {
          tab: "Wallet",
          key_name: "wallet",
        },
      ],
      // END:: TABS DATA

      // START:: PROFILE DATA
      profileData: {
        fullName: "Mahmoud Siliman",
        phone: "0108445645665",
        email: "siliman@test.com",
        wallet: 2500,
        date: "5 - 1 - 2022",
        time: "3 : 23 PM",
        country: "Egypt",
        city: "Mansourah",
        activationStatus: "Active",
        blockStatus: "Not Blocked",
        blockReason: null,
      },
      // END:: PROFILE DATA

      // START:: WALLET DATA
      walletData: {
        debitBalance: 0,
        walletAmount: 2500,
      },
      // END:: WALLET DATA

      // START:: RATE DATA
      rateData: {
        rate: 3.5,
        ratesCount: 1,
      },
      // END:: RATE DATA
    };
  },
};
</script>
