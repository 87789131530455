<template>
  <div class="create_wrapper">
    <!-- START:: CARD TITLE -->
    <div class="card-header">
      <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
    </div>
    <!-- END:: CARD TITLE -->

    <!-- START:: UPDATE FORM WRAPPER -->
    <form @submit.prevent="validateCreateForm">
      <div class="container">
        <div class="row justify-content-between">
          <!-- START:: INPUT WRAPPER -->
          <uplode-image-2 @inputChanged="uplodeImg_1"></uplode-image-2>
          <!-- END:: INPUT WRAPPER -->

          <!-- START:: INPUT WRAPPER -->
          <div class="col-lg-12 py-0">
            <!-- START:: NAME INPUT -->
            <div class="input_wrapper top_label">
              <ckeditor
                type="text"
                class="form-control"
                id="content_input"
                @input="checkIfInputIsEmpty"
                v-model.trim="updateData.content"
              ></ckeditor>
              <label for="content_input" class="form-label">{{
                $t("setting.content")
              }}</label>
            </div>
            <!-- END:: NAME INPUT -->
          </div>
          <!-- END:: INPUT WRAPPER -->
        </div>
      </div>

      <div class="buttons_wrapper">
        <!-- START:: BUTTON -->
        <button
          class="button_style_1"
          :class="{buttonIndex: btnIsLoading}"
          :disabled="btnIsLoading"
        >
          {{ $t("Forms.submit") }}
          <span class="btn_loader" v-if="btnIsLoading"></span>
        </button>
        <!-- END:: BUTTON -->
      </div>
    </form>
    <!-- END:: UPDATE FORM WRAPPER -->
  </div>
</template>

<script>
export default {
  name: "Update",

  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // START:: CREATE DATA
      updateData: {
        avatar: null,
        content: null,
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.updateData.avatar = obj;
    },
    // END:: Uplode Avatar

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;

        if (!this.updateData.avatar?.img_src) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("setting.validation.image"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.updateData.content) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("setting.content"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },

  mounted() {
    this.updateData.title = "Mahmoud Siliman";
    this.updateData.single_select_value = {id: 2, name: "Option_2"};
  },
};
</script>
