export default {
    token(state) {
        return state.token;
    },
    usertype(state) {
        return state.usertype;
    },
    set_userId(state) {
        return state.user_id;
    },
    userImage(state) {
        return state.userImage;
    },
    userPhone(state) {
        return state.userPhone;
    },
    fullNmae(state) {
        return state.fullName;
    },
    userCode(state) {
        return state.userCode;
    },
};