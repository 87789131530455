<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb :items="items" />
      <!-- End Breadcrumb -->

      <!-- Start Main Section -->
      <main class="main-table">
        <!-- START:: IMAGE SELECT INPUT -->
        <div class="headSection py-0">
          <div class="input_wrapper top_label file_input w-100">
            <span class="file_input_label"> صورة أعلي الفاتورة </span>
            <label
              for="tableTopImage"
              class="form-label"
              v-if="!selectedTableTopImage.file"
            ></label>
            <label for="tableTopImage" class="form-label" v-else>
              {{ selectedTableTopImage.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="tableTopImage"
              @change="handelSelectedTableTopImage"
            />
          </div>
        </div>
        <!-- END:: IMAGE SELECT INPUT -->

        <!-- START:: TABLE -->
        <div class="headSection p-2">
          <v-simple-table class="w-100">
            <template v-slot:default>
              <!-- START:: TABLE HEAD -->
              <thead>
                <tr>
                  <th
                    class="text-center"
                    v-for="item in headers"
                    :key="item.id"
                    :width="item.width"
                  >
                    {{ item.text }}
                  </th>
                </tr>
              </thead>
              <!-- END:: TABLE HEAD -->

              <!-- START:: TABLE BODY -->
              <tbody>
                <tr v-for="item in rows" :key="item.id">
                  <td class="text-center">
                    {{ item.name }}
                  </td>

                  <td class="text-center">
                    <div class="input_wrapper icon_label m-3 p-1">
                      <multiselect
                        v-model="item.quantity_name"
                        :options="quantityNames"
                        label="name"
                        track-by="name"
                        placeholder="إسم الكمية"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        :open-direction="'bottom'"
                      >
                      </multiselect>
                    </div>
                  </td>

                  <td class="text-center">
                    <div class="input_wrapper icon_label m-3 p-1">
                      <input
                        type="text"
                        placeholder="السعر"
                        class="form-control"
                        v-model="item.price"
                      />
                    </div>
                  </td>

                  <td class="text-center">
                    <div class="input_wrapper icon_label m-3 p-1">
                      <input
                        type="text"
                        placeholder="الكمية"
                        class="form-control"
                        v-model="item.quantity"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- END:: TABLE BODY -->
            </template>
          </v-simple-table>
        </div>
        <!-- END::TABLE -->

        <!-- START:: DATE INPUT -->
        <div class="headSection py-0">
          <div class="input_wrapper top_label date_input w-100">
            <label class="form-label"> تاريخ إصدار الفاتورة </label>
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="500"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="data.date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.date"
                @input="dateMenu = false"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
        <!-- END:: DATE INPUT -->

        <!-- START:: IMAGE SELECT INPUT -->
        <div class="headSection py-0">
          <div class="input_wrapper top_label file_input w-100">
            <span class="file_input_label"> صورة أسفل الفاتورة </span>
            <label
              for="tableBottomImage"
              class="form-label"
              v-if="!selectedTableBottomImage.file"
            ></label>
            <label for="tableBottomImage" class="form-label" v-else>
              {{ selectedTableBottomImage.name }}
            </label>
            <input
              type="file"
              class="form-control"
              id="tableBottomImage"
              @change="handelSelectedTableBottomImage"
            />
          </div>
        </div>
        <!-- END:: IMAGE SELECT INPUT -->
      </main>
      <!-- End Main Section -->

      <!-- START:: RECEIPT DETAILS  -->
      <div class="headSection flex-column align-items-start">
        <h4 class="my-3">تفاصيل الفاتورة</h4>

        <ul class="all-state-count">
          <li class="flex-grow-1">
            <div class="state-count">
              <span> إسم العميل :</span>
              <span> Client Name Will Go Here </span>
            </div>
          </li>

          <li class="flex-grow-1">
            <div class="state-count">
              <span> إسم الشركة :</span>
              <span> Company Name Will Go Here </span>
            </div>
          </li>

          <li class="flex-grow-1">
            <div class="state-count">
              <span>المبلغ الإجمالي :</span>
              <span> 5000 دولار </span>
            </div>
          </li>

          <li class="flex-grow-1">
            <div class="state-count">
              <span>المبلغ المدفوع :</span>
              <span> 4000 دولار </span>
            </div>
          </li>

          <li class="flex-grow-1">
            <div class="state-count">
              <span>المبلغ المتبقي :</span>
              <span> 1000 دولار </span>
            </div>
          </li>

          <li class="flex-grow-1">
            <div class="state-count">
              <span> كود Swift :</span>
              <span> Swift Code Will Go Here </span>
            </div>
          </li>

          <li class="flex-grow-1">
            <div class="state-count">
              <span> إسم المؤسسة :</span>
              <span> Org Name Will Go Here </span>
            </div>
          </li>
        </ul>
      </div>
      <!-- END:: RECEIPT DETAILS  -->

      <div class="buttons_wrapper">
        <!-- START:: BUTTON -->
        <button
          type="button"
          class="button_style_1"
          :class="{ buttonIndex: loading }"
          :disabled="{ disabled: loading }"
        >
          حفظ
          <span class="btn_loader" v-if="loading"></span>
        </button>
        <!-- END:: BUTTON -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import uploadImage from "@/components/uplods/UplodeImage.vue";

export default {
  name: "AddReceipt",

  components: {
    StatsCard,
    uploadImage,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "إضافة فاتورة",
          disabled: true,
          href: "",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      itemtoDelete: null,

      // ========== Your Data
      rows: [], // injected in created

      quantityNames: [
        { id: 1, name: "Option_1" },
        { id: 2, name: "Option_2" },
        { id: 3, name: "Option_3" },
        { id: 4, name: "Option_4" },
        { id: 5, name: "Option_5" },
      ],

      // ========== Loding
      lodaing: false,
      table_length: null,

      // Date_menus
      dateMenu: false,

      // START:: SELECTED FILE DATA
      selectedTableTopImage: {
        file: null,
        name: null,
        path: null,
      },
      selectedTableBottomImage: {
        file: null,
        name: null,
        path: null,
      },
      // END:: SELECTED FILE DATA

      // START:: REQUEST DATA
      data: {
        date: new Date().toISOString().slice(0, 10),
      },
      // END:: REQUEST DATA
    };
  },

  watch: {
    [`paginations.current_page`]() {
      this.setRows();
    },
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            id: 1,
            text: "الإسم",
            value: "name",
            width: "150px",
          },
          {
            id: 2,
            text: " إسم الكمية",
            value: "quantity_name",
            width: "150px",
          },
          {
            id: 3,
            text: "السعر",
            value: "price",
            width: "150px",
          },
          {
            id: 4,
            text: "الكمية",
            value: "quantity",
            width: "150px",
          },
        ];
      } else {
        return [
          {
            id: 1,
            text: "الإسم",
            value: "name",
            width: "150px",
          },
          {
            id: 2,
            text: " إسم الكمية",
            value: "quantity_name",
            width: "150px",
          },
          {
            id: 3,
            text: "السعر",
            value: "price",
            width: "150px",
          },
          {
            id: 4,
            text: "الكمية",
            value: "quantity",
            width: "150px",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/wallet/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.lodaing = false;
      this.rows = [
        {
          id: 1,
          name: "Name 1",
          quantity_name: null,
          price: null,
          quantity: null,
        },
        {
          id: 2,
          name: "Name 2",
          quantity_name: null,
          price: null,
          quantity: null,
        },
      ];
    },

    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedTableTopImage(e) {
      this.selectedTableTopImage.file = e.target.files[0];
      this.selectedTableTopImage.name = e.target.files[0].name;
      this.selectedTableTopImage.path = URL.createObjectURL(e.target.files[0]);
    },
    handelSelectedTableBottomImage(e) {
      this.selectedTableBottomImage.file = e.target.files[0];
      this.selectedTableBottomImage.name = e.target.files[0].name;
      this.selectedTableBottomImage.path = URL.createObjectURL(
        e.target.files[0]
      );
    },
    // END:: HANDLING SELECTED FILE METHOD
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>

