<template>
  <div class="please-search">
    <span class="icon">
      <i class="fas fa-box-full"></i>
    </span>
    <h3 v-if="type == 'english'">Search for more result</h3>
    <h3 v-else>قم بالبحث لظهور النتائج</h3>
  </div>
</template>
<script>
export default {
  props: ["type"],
  // props: {
  //   show: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },
};
</script>
