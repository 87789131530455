<template>
  <div class="wraaper">
    <section class="headSection">
      <div class="d-flex bread_flex">
        <v-breadcrumbs divider=">>" :items="items">
          <template v-slot:item="{ item }">
            <router-link :to="item.href" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </router-link>
          </template>
        </v-breadcrumbs>

        <div class="filter_icon">
          <router-link :to="search_route" tag="a" class="ml-4" v-if="search">
            <span v-if="type == 'english'">
              Search at {{ search_title }} <i class="far fa-search mr-1"></i>
            </span>
            <span v-else>
              <i class="far fa-search ml-1"></i> بحث في {{ search_title }}</span
            >
          </router-link>
          <button
            @click="printSection"
            class="btn"
            type="button"
            v-if="show_print"
          >
            <span v-if="type == 'english'">
              Print Table <i class="fal fa-print"></i
            ></span>
            <span v-else><i class="fal fa-print"></i> طباعة الجدول </span>
          </button>
          <button type="button" class="mr-2">
            <download-excel
              class="button-print btn-excel"
              :data="excelData.items"
              :export-fields="excelData.json_fields"
              :title="excelData.name"
              :name="excelData.name + '.xls'"
              v-if="excelFile"
              :footer='excelData.footer'
            >
              
              <span v-if="type == 'english'">Excel file <span class="icon"><i class="far fa-file-excel"></i></span></span>
              <span v-else><span class="icon"><i class="far fa-file-excel"></i></span> ملف إكسل</span>
            </download-excel>
          </button>
        </div>
      </div>
    </section>
    <iframe class="iframePrint" id="printf" name="printf"> </iframe>
  </div>
</template>

<script>
export default {
  props: [
    "items",
    "title",
    "show_print",
    "show_filter",
    "search",
    "search_title",
    "search_route",
    "type",
    "excelFile",
    "excelData"
  ],

  emits: ["openFilter"],

  methods: {
    openFilter() {
      this.$emit("openFilter");
    },
    // print
    printSection() {
      var iframePrint = document.querySelector(".iframePrint");
      var iframeHead = document.head.innerHTML;
      var printContents = document.querySelector(".table-print").innerHTML;
      iframePrint.contentWindow.document.write(iframeHead + printContents);
      setTimeout(() => {
        window.frames["printf"].print();
        iframePrint.contentWindow.document.close();
      }, 500);
    },
  },
};
</script>

