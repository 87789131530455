<template>
  <!-- Start Loading Page -->
  <div id="preloader">
    <div class="preloader">
      <div class="loader fadeIn">
        <img
          class="image-loader"
          :src="
            current_theme == 'light'
              ? require('@/assets/images/icons/fav-dark.png')
              : require('@/assets/images/icons/fav-white.png')
          "
        />
        <h1>
          <span class="g">G</span>
          <span class="o">o</span>
          <span class="l">l</span>
          <span class="d">d</span>
          <span class="e">e</span>
          <span class="n">n</span>
          <span class="e2">E</span>
          <span class="y">y</span>
          <span class="e3">e</span>
          <span class="s">s</span>
        </h1>
      </div>
    </div>
  </div>
  <!-- End Loading Page -->
</template>
<script>
export default {
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
  },
};
</script>

<style scoped>
.sub-loader {
  position: relative !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--mainBgColor) ;
  z-index: 5;
  height: 80vh;
}
#preloader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--mainBgColor);
  z-index: 5;
}
.loader {
  width: 100%;
  height: 50px;
  position: absolute;
  top: calc(38% - 17px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader h1 {
  text-align: center;
}

.loader span {
  -webkit-animation: fade_loader 2s infinite ease;
  animation: fade_loader 2s infinite ease;
  font-family: auto;
  font-weight: bold;
  color: var(--loaderColor);
}

.loader span.g {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.loader span.o {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loader span.l {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.loader span.d {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader span.e {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.loader span.n {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.loader span.e2 {
  margin-left: 15px;
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
}
.loader span.y {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.loader span.e3 {
  -webkit-animation-delay: 2.25s;
  animation-delay: 2.25s;
}
.loader span.s {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
@-webkit-keyframes fade_loader {
  0% {
    color: var(--loaderColor);
  }
  50% {
    color: var(--mainBgColor);
  }
  100% {
    color: var(--loaderColor);
  }
}

@keyframes fade_loader {
  0% {
    color: var(--loaderColor);
  }
  50% {
    color: var(--mainBgColor);
  }
  100% {
    color: var(--loaderColor);
  }
}
.image-loader {
  width: 55px;
  position: relative;
  margin: 0 auto;
  top: 10px;
}
</style>

