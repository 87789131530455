<template>
  <div class="filter_menu custom_card" :class="{ show: show }">
    <div class="close_filter">
      <button class="btn" @click="$emit('closeFilter')">
        <i class="fal fa-times"></i>
      </button>
    </div>
    <v-form @submit.prevent="$emit('submit_filter', filter)">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <div class="input_wrapper top_label">
              <input
                type="text"
                class="form-control"
                id="name_input"
                v-model.trim="filter.name"
              />
              <label for="name_input" class="form-label">اسم المستخدم</label>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="input_wrapper top_label">
              <label class="form-label">الخزنة</label>
              <multiselect
                v-model="filter.safe"
                :options="safes"
                label="name"
                track-by="name"
                placeholder=" "
                :searchable="true"
                :allow-empty="false"
                :show-labels="false"
              >
              </multiselect>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <div class="input_wrapper top_label date_input">
              <label class="form-label">من</label>
              <v-menu
                ref="from_date_menu"
                v-model="from_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.from"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filter.from" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="from_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.from_date_menu.save(filter.from)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <div class="input_wrapper top_label date_input">
              <label class="form-label">الى</label>
              <v-menu
                ref="to_date_menu"
                v-model="to_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.to"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filter.to" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="to_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.to_date_menu.save(filter.to)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <div class="buttons_wrapper">
              <button type="submit" class="button_style_1">حفظ</button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
export default {
  props: ["show"],

  data() {
    return {
      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Fixed_data
      safes: [
        { id: 1, name: "Option_1" },
        { id: 2, name: "Option_2" },
        { id: 3, name: "Option_3" },
        { id: 4, name: "Option_4" },
        { id: 5, name: "Option_5" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>

