<template>
  <div class="uplodeImage">
    <div class="chooseImage">
      <transition name="fadeIn" mode="out-in">
        <img v-if="image.img_src" :src="image.img_src" />
        <img v-else-if="data_src" :src="data_src" />
        <img v-else src="../../assets/images/uplode.png" />
      </transition>
      <input
        ref="uplode_file"
        class="file_input"
        type="file"
        accept="image/*"
        name="imageToUplode"
        @change="chooseImage"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["inputChanged"],

  props: {
    data_src: {
      default: null,
      required: false,
    },
  },

  data() {
    return {
      image: {
        img_file: null,
        img_src: "",
      },
    };
  },

  methods: {
    chooseImage(e) {
      this.image.img_file = e.target.files[0];

      if (this.image.img_file) {
        this.image.img_src = URL.createObjectURL(this.image.img_file);
        this.uplodeDisabled = false;
      }

      this.$emit("inputChanged", this.image);
      e.target.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.uplodeImage {
  .chooseImage {
    position: relative;
    width: 300px;
    text-align: center;
    cursor: pointer;
    margin: 10px auto;

    img {
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      padding: 6px 0;
      display: inline-block;
      font-size: 15px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .file_input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: inline-block;
      opacity: 0;
      cursor: pointer;
    }
  }

  .image {
    width: 50%;
    margin: 20px auto;

    @media (max-width: 900px) {
      width: 70%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      min-height: 300px;
      max-height: 300px;
    }
  }
}
</style>
