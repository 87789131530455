<template>
  <div class="search-section">
    <div class="divider">
      <h4>بحث في تقارير المحذوفات</h4>
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--dark"
      />
    </div>
    <div class="row">
      <div class="col-lg-4 py-0">
        <div class="input_wrapper top_label auto_complete">
          <label class="form-label"> نوع المحذوفات </label>
          <multiselect
            v-model="searchData.type"
            :options="searchOptions.types"
            label="name"
            track-by="id"
            @input="
              chooseLock(searchData.type), $emit('filterTable', searchData)
            "
            placeholder=" "
            :searchable="true"
            :allow-empty="true"
            :show-labels="false"
          >
          </multiselect>
        </div>
      </div>

      <div class="col-lg-4 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">من</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.from_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.from"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.from"
              @input="searchOptions.from_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="col-lg-4 py-0">
        <div class="input_wrapper top_label date_input">
          <label class="form-label">إلي</label>
          <v-menu
            ref="to_date_menu"
            v-model="searchOptions.to_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchData.to"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchData.to"
              @input="searchOptions.to_date_menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="col-lg-12 text-center py-0">
        <button
          class="button_style_1"
          @click="$emit('filterTable', searchData)"
        >
          بحث
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
  data() {
    return {
      load_users: false,
      load_enterprices: false,

      // search
      searchData: {
        typeVal: null,
        type: null,
        from: new Date().toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
        from_date_menu: false,
        to_date_menu: false,
      },
      searchOptions: {
        types: [
          {
            id: 1,
            value: "lock",
            name: "الخزن",
          },
          {
            id: 2,
            value: "transfare_amount",
            name: "نقل الأرصدة",
          },
          {
            id: 2,
            value: "transfare_amount",
            name: "نقل الأرصدة",
          },
          {
            id: 3,
            value: "clients",
            name: "العملاء",
          },
          {
            id: 4,
            value: "clients",
            name: "العملاء",
          },
          {
            id: 5,
            value: "deposit_operation",
            name: "معاملات الإيداع",
          },
          {
            id: 6,
            value: "withdraw_operation",
            name: "معاملات السحب",
          },
          {
            id: 7,
            value: "client_account",
            name: "حسابات عميل",
          },
          {
            id: 8,
            value: "banks",
            name: "البنوك",
          },
          {
            id: 9,
            value: "dubai_banks",
            name: "بنوك دبي",
          },
          {
            id: 10,
            value: "dubai_banks_deposits",
            name: "إضافة ارصدة بنوك دبي",
          },
          {
            id: 11,
            value: "dubai_banks_withdraw",
            name: "سحب أرصدة بنوك دبي",
          },
          {
            id: 12,
            value: "bank_deposit",
            name: "إيداع بنكي",
          },
          {
            id: 13,
            value: "bank_withdraw",
            name: "سحب بنكي",
          },
          {
            id: 14,
            value: "currency",
            name: "العملات",
          },
          {
            id: 15,
            value: "currency_buy",
            name: "شراء عملة",
          },
          {
            id: 16,
            value: "currency_sale",
            name: "بيع عملة",
          },
          {
            id: 17,
            value: "currency_transfare",
            name: "تحويل عملة لعميل",
          },
          {
            id: 18,
            value: "service_deposit",
            name: "إيداع سيرفس",
          },
          {
            id: 19,
            value: "service_widthdraw",
            name: "سحب سيرفس",
          },
          {
            id: 20,
            value: "expence",
            name: "المصروفات",
          },
          {
            id: 21,
            value: "enterprise_account",
            name: "أصحاب المؤسسات",
          },
          {
            id: 22,
            value: "enterprise",
            name: "المؤسسات",
          },
          {
            id: 23,
            value: "enterprise_amount",
            name: "حسابات المؤسسات",
          },
          {
            id: 24,
            value: "categories",
            name: "مجالات المؤسسات",
          },
          {
            id: 25,
            value: "enterprise_reset",
            name: "إيصالات المؤسسات",
          },
          {
            id: 26,
            value: "ATM",
            name: "إيداع بنكي أو ATM",
          },
          {
            id: 27,
            value: "companies",
            name: "الشركات",
          },
          {
            id: 28,
            value: "products",
            name: "السلع",
          },
          {
            id: 29,
            value: "company_action",
            name: "تنفيذ شغل الشركات",
          },
          {
            id: 30,
            value: "enterprise_action",
            name: "تنفيذ شغل المؤسسات",
          },
          {
            id: 31,
            value: "company_cach",
            name: "أقساط الشركات",
          },
          {
            id: 32,
            value: "branchs",
            name: "الفروع",
          },
          {
            id: 33,
            value: "employees",
            name: "الموظفين",
          },
          {
            id: 34,
            value: "countries",
            name: "الدول",
          },
        ],
      },
      load_companies: false,
    };
  },
  watch: {
    [`searchOptions.search_users`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.users = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_users(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_users(newVal);
        } else {
          this.get_filterd_users(newVal);
        }
      }
    },
  },
  methods: {
    // get_filterd_users
    get_filterd_users(keyword) {
      this.load_users = true;
      this.$axios({
        method: "GET",
        url: `client/user/search?keyword=${keyword}`,
      })
        .then((res) => {
          this.users = res.data.data;
          this.load_users = false;
        })
        .catch((err) => {
          this.load_users = false;
        });
    },

    chooseLock(item) {
      if (this.searchData.type) {
        this.searchData.typeVal = item.value;
      } else {
        this.searchData.typeVal = null;
      }
    },
  },
};
</script>

<style lang="scss">
.search-section .load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>
