<template>
  <div class="print-header">
    <div class="head" v-if="!hideHeader">
      <h1>طباعة بواسطة : {{ userCode }}</h1>
      <h2>{{ date }}</h2>
    </div>
    <h3>كشف عن {{ title }}</h3>
  </div>
</template>
<script>
export default {
  props: ["title", "number", "hideHeader"],
  data() {
    return {
      userName: localStorage.getItem("Golden_App_fullName"),
      userCode: localStorage.getItem("Golden_App_userCode"),
      date: new Date().toISOString().slice(0, 10),
    };
  },
};
</script>

