<template>
  <div class="show_transaction">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4" v-for="item in statisticsItems" :key="item.id">
          <stats-card
            :icon="item.icon"
            :title="item.number"
            :number="item.title"
            :color="current_theme == 'dark'? '#525e6e':'#acc4d9'
            :bgColor="'transparent'"
          ></stats-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  data() {
    return {
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-books",
          title: "اسم المستخدم",
          number: "احمد",
        },
        {
          id: 2,
          icon: "fal fa-books",
          title: "رقم الهاتف",
          number: "0102003654",
        },
        {
          id: 3,
          icon: "fal fa-books",
          title: "البريد الالكتروني",
          number: "admin@email.com",
        },
        {
          id: 4,
          icon: "fal fa-books",
          title: "الدولة",
          number: "مصر",
        },
        {
          id: 5,
          icon: "fal fa-books",
          title: "الحالة",
          number: "قيد الانتظار",
        },
        {
          id: 6,
          icon: "fal fa-books",
          title: "اسم البنك",
          number: "البنك الاهلي",
        },
        {
          id: 7,
          icon: "fal fa-books",
          title: "اسم الحساب",
          number: "اسم الحساب",
        },
        {
          id: 8,
          icon: "fal fa-books",
          title: "A/C NO",
          number: "71254666695512",
        },
        {
          id: 9,
          icon: "fal fa-books",
          title: "الكمية",
          number: "450",
        },
      ],
    };
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
  },
  components: {
    StatsCard,
  },
};
</script>
