export default {
    set_contracts(state, payload) {
        state.contracts = payload;
    },

    set_transfares(state, payload) {
        state.transfares = payload;
    },

    set_messages(state, payload) {
        state.messages = payload;
    },

    set_requests(state, payload) {
        state.requests = payload;
    },

    set_influencers(state, payload) {
        state.influencers = payload;
    },

    set_investors(state, payload) {
        state.investors = payload;
    },
    set_platformRequests(state, payload) {
        state.platformRequests = payload;
    },
    set_notification(state, payload) {
        state.notification = payload;
    },
    set_loading(state, payload) {
        state.loading = payload;
    }
};