import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,

    state() {
        return {
            contracts: [],
            transfares: [],
            messages: [],
            requests: [],
            influencers: [],
            investors: [],
            platformRequests: [],
            notification: [],
            loading: false,
        };
    },
    mutations,
    actions,
    getters,
};