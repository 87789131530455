<template>
  <div class="maps">
    <!-- Start Breadcrumb -->
    <Breadcrumb title="Maps" :items="items" />
    <!-- End Breadcrumb -->

    <!-- Start ========== Map_1 -->
    <div class="custom_card">
      <p>Read_Only Map Single</p>

      <div class="mt-5">
        <GmapMap
          :center="center_1"
          :zoom="7"
          map-type-id="terrain"
          style="width: 100%; height: 400px"
        >
          <GmapMarker
            v-for="(m, index) in markers_1"
            :key="index"
            :position="m.position"
          />
        </GmapMap>
      </div>
    </div>
    <!-- End ========== Map_1 -->

    <!-- Start ========== Map_2 -->
    <div class="custom_card">
      <p>Read_Only Map Multible</p>

      <div class="mt-5">
        <GmapMap
          :center="center_2"
          :zoom="3"
          map-type-id="terrain"
          style="width: 100%; height: 400px"
        >
          <GmapMarker
            v-for="(m, index) in markers_2"
            :key="index"
            :position="m.position"
          />
        </GmapMap>
      </div>
    </div>
    <!-- End ========== Map_2 -->

    <!-- Start ========== Map_3 -->
    <div class="custom_card">
      <p>Edit_Only Map</p>

      <div class="mt-5">
        <GmapMap
          :center="center_2"
          :zoom="3"
          map-type-id="terrain"
          style="width: 100%; height: 400px"
        >
          <GmapMarker
            v-for="(m, index) in markers_3"
            :key="index"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @drag="updateCoordinates"
            @click="center = m.position"
          />
        </GmapMap>
        <p>lat : {{ coordinates_to_edit.lat }}</p>
        <p>lng : {{ coordinates_to_edit.lng }}</p>
      </div>
    </div>
    <!-- End ========== Map_3 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Maps",
          disabled: true,
          href: "",
        },
      ],

      // ========== Map_1
      center_1: null,
      markers_1: null,

      // ========== Map_2
      center_2: null,
      markers_2: null,

      // ========== Map_3
      center_3: null,
      markers_3: null,
      coordinates_to_edit: { lat: 0, lng: 0 },
    };
  },

  methods: {
    // ========== Map_1
    setMap_1() {
      this.markers_1 = [{ position: { lat: 10.0, lng: 10.0 } }];
      this.center_1 = this.markers_1[0].position;
    },

    // ========== Map_2
    setMap_2() {
      this.markers_2 = [
        { position: { lat: 10.0, lng: 10.0 } },
        { position: { lat: 15, lng: 15 } },
        { position: { lat: 40, lng: 30 } },
      ];
      this.center_2 = this.markers_2[0].position;
    },

    // ========== Map_3
    setMap_3() {
      this.markers_3 = [{ position: { lat: 10.0, lng: 10.0 } }];
      this.center_3 = this.markers_3[0].position;
    },
    updateCoordinates(location) {
      this.coordinates_to_edit = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    clickMethod(m) {
      this.center = m.position;
    },

    // ============== Get User Location
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.$axios({
            method: "get",
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAITrPfT5r_qmCm_8ekZyPmnebGo8o_r18`,
          })
            .then((res) => {
              console.log(res.data.results[0].formatted_address);
            })
            .catch(() => {});
        });
      }
    },
  },

  created() {
    this.setMap_1();
    this.setMap_2();
    this.setMap_3();

    // ===========
    this.getLocation();
  },
};
</script>
