<template>
  <div class="login">
    <div class="login_box">
      <div class="inputs custom_card" v-if="!showOtherSection">
        <div class="card-header">
          <div class="log-img">
            <img src="@/assets/images/icons/logo-white.svg" />
          </div>
          <h4>{{ $t("auth.title") }}</h4>
          <p>{{ $t("auth.desc") }}</p>
        </div>
        <form class="form form-horizontal" @submit.prevent="validateLoginForm">
          <div class="form-body">
            <div class="row">
              <div class="col-12">
                <!-- START:: EMAIL INPUT -->
                <div class="input_wrapper animated_placeholder">
                  <input
                    type="email"
                    class="form-control"
                    id="login_email"
                    v-model.trim="email"
                    @input="checkIfInputIsEmpty"
                    autocomplete="off"
                    autofill="off"
                    value=""
                  />
                  <label for="login_email" class="form-label"
                    >البريد الإلكتروني</label
                  >
                </div>
                <!-- END:: EMAIL INPUT -->

                <!-- START:: PASSWORD INPUT -->
                <div class="input_wrapper animated_placeholder">
                  <button
                    type="button"
                    class="toggle_pass_btn"
                    @click="togglePasswordVisibility"
                  >
                    <i class="fal fa-eye open_eye"></i>
                    <i class="fal fa-eye-slash closed_eye"></i>
                  </button>
                  <input
                    type="password"
                    class="form-control"
                    id="login_pass"
                    v-model.trim="password"
                    @input="checkIfInputIsEmpty"
                    autocomplete="off"
                    autofill="off"
                    value=""
                  />
                  <label for="login_pass" class="form-label">كلمة المرور</label>
                </div>
                <router-link to="/reset-code" class="reset-code">
                  تغيير كلمة المرور ؟
                </router-link>
                <!-- END:: PASSWORD INPUT -->
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              متابعة
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
      </div>
      <!-- ------------------------------===================================-------------------------- -->
      <div class="inputs custom_card" v-if="showOtherSection">
        <div class="card-header">
          <div class="log-img">
            <img src="@/assets/images/icons/logo-white.svg" />
          </div>
          <h4>{{ $t("auth.title") }}</h4>
          <p>يرجي إدخال الكود المرسل علي Golden Eyes Token App</p>
        </div>
        <form
          class="form form-horizontal"
          dir="ltr"
          @submit.prevent="validateCodeForm"
        >
          <div class="form-body">
            <div class="row">
              <div class="col-12">
                <!-- START:: EMAIL INPUT -->
                <div class="input_wrapper verification_inputs_wrapper">
                  <input
                    ref="first_input"
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="firstNumber"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="secondNumber"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="thirdNumber"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @keyup="toggleFocus"
                    v-model.trim="fourthNumber"
                  />
                </div>
                <!-- END:: EMAIL INPUT -->
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <!-- START:: BUTTON -->
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تسجيل دخول
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
            <!-- END:: BUTTON -->
          </div>
        </form>
      </div>
      <!-- Image Side -->
      <div class="image">
        <img src="@/assets/images/pages/login/white.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Loading
      btnIsLoading: false,
      email: null,
      password: null,
      loginData: {
        token: null,
        userType: null,
        userId: null,
        userImage: null,
        userPhone: null,
        fullName: null,
        userCode: null,
      },
      showOtherSection: false,
      // START:: VERIFICATION INPUTS DATA
      firstNumber: null,
      secondNumber: null,
      thirdNumber: null,
      fourthNumber: null,
      // END:: VERIFICATION INPUTS DATA
      // START:: TIMER DATA
      timerCount: 60,
      // END:: TIMER DATA
    };
  },

  methods: {
    // START:: TOGGLE INPUTS FOCUS
    toggleFocus(e) {
      if (e.currentTarget.value.length == 1) {
        if (e.currentTarget.nextSibling) {
          e.currentTarget.blur();
          e.currentTarget.nextSibling.focus();
        }
      } else if (e.currentTarget.value.length == 0) {
        if (e.currentTarget.previousSibling) {
          e.currentTarget.blur();
          e.currentTarget.previousSibling.focus();
        }
      }
    },
    // END:: TOGGLE INPUTS FOCUS
    // START:: COUNTDOWN TIMER
    countDown() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1;
          this.countDown();
        }, 1000);
      }
    },
    // END:: COUNTDOWN TIMER
    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: TOGGLE PASSWORD VISIBILITY METHOD
    togglePasswordVisibility(e) {
      let passwordElement = e.currentTarget.parentElement.children[1];
      let passwordTogglerBtn = e.currentTarget;
      if (passwordElement.type == "password") {
        passwordElement.type = "text";
        passwordTogglerBtn.classList.add("password_is_visible");
      } else if (passwordElement.type == "text") {
        passwordElement.type = "password";
        passwordTogglerBtn.classList.remove("password_is_visible");
      }
    },
    // END:: TOGGLE PASSWORD VISIBILITY METHOD

    launch_resize_dispatch() {
      this.$store.dispatch("sideNav_module/onResize");
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    // START:: VALIDATE LOGIN FORM
    validateLoginForm() {
      this.btnIsLoading = true;
      if (!this.email) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرحي إدخال البريد الإلكتروني",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.password) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إدخال كلمة المرور",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.btnIsLoading = true;
        const data = new FormData();
        data.append("username", this.email);
        data.append("password", this.password);
        this.$axios
          .post("login", data)
          .then((res) => {
            console.log(res)
            this.btnIsLoading = false;
            this.showOtherSection = true;
            this.countDown();
            this.$refs.first_input.focus();
          })
          .catch((err) => {
          console.log(err)
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
          });
      }
    },
    // START:: SUBMIT Code Form
    validateCodeForm() {
      this.btnIsLoading = true;
      if (!this.email) {
        this.$iziToast.error({
          timeout: 2000,
          message: "يرجي إدخال كود التفعيل",
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.btnIsLoading = true;
        const data = new FormData();
        data.append("email", this.email);
        var code =
          this.firstNumber +
          this.secondNumber +
          this.thirdNumber +
          this.fourthNumber;
        data.append("code", code);
        this.$axios
          .post("verify", data)
          .then((res) => {
            this.btnIsLoading = false;
            this.loginData.token = res.data.data.token;
            this.loginData.userType = res.data.data.type;
            this.loginData.userId = res.data.data.id;
            this.loginData.userImage = res.data.data.image;
            this.loginData.userPhone = res.data.data.phone;
            this.loginData.fullName = res.data.data.full_name;
            this.loginData.userCode = res.data.data.user_code;
            this.$store.dispatch("auth_module/logIn", this.loginData);
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("global.loginSuccess"),
              position: "topRight",
            });
          })
          .catch((err) => {
            this.btnIsLoading = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "topRight",
            });
          });
      }
    },
    // END:: SUBMIT Code FORM
  },

  mounted() {
    if (this.$store.getters.resizeEventExisit == true) {
      window.removeEventListener("resize", this.launch_resize_dispatch, true);
      this.$store.commit("changeRessizeEvent");
    }

    document.querySelector("body").style.padding = "0 !important";
  },
};
</script>
