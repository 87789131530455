<template>
  <div class="all_settings custom_card">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-sm-12">
          <!--START:: SETTINGS TABS-->
          <div class="settings_tabs">
            <ul class="nav_pills">
              <li class="nav_item">
                <router-link class="nav_link" to="/aboutUs">{{
                  $t("settings.about")
                }}</router-link>
              </li>
              <li class="nav_item">
                <router-link class="nav_link" to="/terms">{{
                  $t("settings.terms")
                }}</router-link>
              </li>
              <li class="nav_item">
                <router-link class="nav_link" to="/policy">{{
                  $t("settings.policy")
                }}</router-link>
              </li>
              <!-- <li class="nav_item">
                <router-link class="nav_link" to="/contactUs">{{
                  $t("settings.contact")
                }}</router-link>
              </li> -->
            </ul>
          </div>
          <!--END:: SETTINGS TABS-->

          <!--START:: SETTINGS TABS Content-->
          <div class="setting_tabs_content">
            <transition name="fadeIn" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </div>
          <!--END:: SETTINGS TABS Content-->
        </div>
      </div>
    </div>
    <book-loader></book-loader>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style></style>
