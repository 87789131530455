<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :title="$t('contactMessages.title')" :items="items" />
    <!--Start Statistics Card-->
    <div class="row mb-5">
      <div class="col-md-3" v-for="item in statisticsItems" :key="item.id">
        <stats-card
          :icon="item.icon"
          :title="item.title"
          :number="item.number"
          :color="current_theme == 'dark' ? '#525e6e':'#acc4d9'"
          :bgColor="'transparent'"
        ></stats-card>
      </div>
    </div>
    <!--End Statistics Card-->
    <v-data-table
      class=" thumb"
      :headers="headers"
      :items="rows"
      :search="search"
      :custom-filter="filterSearch"
      :loading="lodaing"
      :loading-text="$t('table.loadingData')"
      v-model="selected"
      :single-select="singleSelect"
      :items-per-page="rows.length"
      item-key="id"
      hide-default-footer
    >
      <!-- ====== You Can use any slots you want ====== -->
      <!-- Select header field -->
      <template v-slot:[`header.name`]="{ header }">
        {{ header.text + " ..." }}
      </template>
      <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>
      <!-- Select row field -->
      <template v-slot:[`item.calories`]="{ item }">
        <v-chip :color="getColor(item.calories)" dark>
          {{ item.calories }}
        </v-chip>
      </template>

      <!-- Select no data State -->
      <template v-slot:no-data>
        {{ $t("table.noData") }}
      </template>

      <!-- Select actions-->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="_actions">
          <v-icon class="show" small @click="showItem(item)">
            fal fa-reply
          </v-icon>
          <v-icon class="delete" small @click="deleteItem(item)">
            fal fa-trash
          </v-icon>
        </div>
      </template>

      <!-- Select Image-->
      <template v-slot:[`item.avatar`]="{ item }">
        <img @click="show_model_1" class="image" :src="item.avatar" />
      </template>
      <!-- ====== You Can use any slots you want ====== -->

      <!-- ======================== Start Top Section ======================== -->
      <template v-slot:top>
        <!-- Delete dialog -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5 justify-center">{{
              $t("table.deletedialog.areYouSure")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
                $t("table.deletedialog.ok")
              }} <span class="btn_loader_model" v-if="loadingBtnDialog"></span></v-btn>
              <v-btn color="#F44336" @click="dialogDelete = false">{{
                $t("table.deletedialog.cancel")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Row -->
        <v-row>
          <v-col cols="12" sm="10">
            <!-- Search -->
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
              class="mx-4"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <section class="table-buttons">
              <button
                type="button"
                class="button_delete ml-5"
                v-if="selected.length != 0"
              >
                <i class="far fa-trash-alt"></i>&nbsp; حذف المحدد
              </button>
            </section>
          </v-col>
        </v-row>
      </template>
      <!-- ======================== End Top Section ======================== -->
    </v-data-table>

    <!-- Model_1 -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: "مشاهدة الكل",
          disabled: true,
          href: "",
        },
      ],
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-envelope-open-text",
          title: "رسائل التواصل",
          number: "10",
        },
      ],
      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
    };
  },
  components: {
    StatsCard,
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم المرسل", value: "sender", align: "center" },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      } else {
        return [
          { text: "Sender Name", value: "sender", align: "center" },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    // Here filter to only uppercase || it exist by default
    filterSearch(value, search, item) {

      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    setRows() {
      this.lodaing = true;

      // let pageIndex;
      // if (pageNumber) {
      //   pageIndex = pageNumber;
      // } else {
      //   pageIndex = 1;
      // }

      setTimeout(() => {
        this.rows = [
          {
            id: 1,
            sender: "احمد على",
            message: "اوجهة مشكلة في",
          },
          {
            id: 2,
            sender: "محمد اسامة",
            message: "اوجهة مشكلة في",
          },
          {
            id: 3,
            sender: "هدى ياسر",
            message: "اوجهة مشكلة في",
          },
          {
            id: 4,
            sender: "عمر خالد",
            message: "اوجهة مشكلة في",
          },
          {
            id: 5,
            sender: "محمود ابراهيم",
            message: "اوجهة مشكلة في",
          },
          {
            id: 6,
            sender: "منى احمد",
            message: "اوجهة مشكلة في",
          },
        ];

        this.lodaing = false;
      }, 2000);
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== CRUD ====================
    showItem(item) {
      this.$router.push({ path: "/showContactMessage/" + item.id });
    },
    editItem(item) {
      this.$router.push({ path: "/editAcademicYear/" + item.id });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.dialogDelete = false;
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
  },
};
</script>

<style lang="scss" scoped></style>
