<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- start of searchSection  -->
      <SearchSection
        @filterTable="searchTable"
        title="أقساط الشركات"
      ></SearchSection>
      <!-- end of searchSection  -->
      <!-- notFound  -->
      <Notfound v-if="!searchItems"></Notfound>
      <!-- Start Statistics Card-->
      <div v-else>
        <!-- Start Statistics Card-->
        <ul class="all-state-count">
          <li>
            <div class="state-count">
              <span>عدد أقساط الشركات :</span>
              <span>{{ table_length }}</span>
            </div>
          </li>
        </ul>
        <!-- End Statistics Card-->

        <main class="main-table table-print">
          <!-- start of  header of printSection  -->
          <PrintHead
            :title="' اقساط الشركات'"
            :number="table_length"
          ></PrintHead>
          <!-- end of header of printSection  -->
          <v-data-table
            class="thumb"
            :headers="headers"
            :items="rows"
            :loading="lodaing"
            :loading-text="$t('table.loadingData')"
            v-model="selected"
            :single-select="singleSelect"
            item-key="id"
            :items-per-page="rows.length"
            hide-default-footer
          >
            <!-- ================== You Can use any slots you want ================== -->
            <!-- ====== Select row field ====== -->
            <!-- Select no data State -->
            <template v-slot:no-data>
              {{ $t("table.noData") }}
            </template>
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ Number(item.amount).toLocaleString() }}
            </template>
            <!-- revision status-->
            <template v-slot:[`item.review_status`]="{ item }">
              <v-chip color="#d7c100" v-if="item.review_status == 0">
                منتظر
              </v-chip>
              <v-chip color="green" v-else> تأكيد </v-chip>
            </template>
            <!-- Confirm revision-->
            <template v-slot:[`item.revision_done`]="{ item }">
              <!--  -->
              <div class="_actions actions2">
                <button
                  type="button"
                  class="accept confirm_btn ml-0"
                  @click="revisionDone(item)"
                  v-if="item.review_status == 0"
                >
                  <i class="far fa-check"></i>
                </button>
                <span v-else> ---- </span>
              </div>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <div class="_actions">
                <v-icon
                  class="show"
                  small
                  @click="showImageModal(item.image)"
                  v-if="item.image"
                >
                  fal fa-eye
                </v-icon>
                <span v-else> ----- </span>
              </div>
            </template>
            <template v-slot:[`item.desc`]="{ item }">
              <div class="_actions">
                <span v-if="item.desc == 'null' || item.desc == null">
                  ----
                </span>
                <v-icon
                  class="show"
                  small
                  v-else
                  @click="
                    modal.notesModalIsOpen = true;
                    setItemNotes(item.desc);
                  "
                >
                  fal fa-eye
                </v-icon>
              </div>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="_actions" v-if="item.status == 0">
                <v-icon class="edit" small @click="editItem(item)">
                  fal fa-edit
                </v-icon>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
              <div class="_actions" v-else>
                <v-icon class="delete" small @click="deleteItem(item)">
                  fal fa-trash
                </v-icon>
              </div>
            </template>
            <!-- Confirm-->
            <template v-slot:[`item.confirm`]="{ item }">
              <!--  -->
              <div class="_actions actions2" v-if="item.status == 0">
                <button
                  type="button"
                  class="accept confirm_btn ml-0"
                  @click="acceptCompany(item.id)"
                >
                  <i class="far fa-check"></i>
                </button>
              </div>
              <span v-else> ---- </span>
            </template>
            <!-- Select actions-->
            <template v-slot:[`item.status`]="{ item }">
              <div class="_actions">
                <v-chip
                  v-if="item.status == 0"
                  class="ma-2"
                  color="red"
                  text-color="white"
                >
                  لم يسدد
                </v-chip>
                <v-chip
                  v-if="item.status == 1"
                  class="ma-2"
                  color="green"
                  text-color="white"
                >
                  سدد
                </v-chip>
              </div>
            </template>
            <!-- ======================== Start Top Section ======================== -->
            <template v-slot:top>
              <!-- Delete dialog -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">{{
                    $t("table.deletedialog.areYouSure")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="deleteItemConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogDelete = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Add & Delete -->
              <!-- confirm dialog -->
              <v-dialog v-model="dialogConfirm" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 justify-center">
                    هل تمت المراجعه علي العملية ؟
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1B5E20" @click="revisionConfirm"
                      >{{ $t("table.deletedialog.ok") }}
                      <span
                        class="btn_loader_model"
                        v-if="loadingBtnDialog"
                      ></span
                    ></v-btn>
                    <v-btn color="#F44336" @click="dialogConfirm = false">{{
                      $t("table.deletedialog.cancel")
                    }}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Description Modal -->
              <NotesModal
                :desc="selectedItemNotes"
                :handelModalApperance="modal.notesModalIsOpen"
                @close="closeNotesModal"
              />
            </template>
            <!-- ======================== End Top Section ======================== -->
          </v-data-table>
        </main>
        <!-- End Main Section -->
      </div>

      <!-- Model_1 -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img v-if="selectedItemImage" :src="selectedItemImage" />
        </div>
      </base-model>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import SearchSection from "@/components/Search/cach";
export default {
  components: {
    StatsCard,
    NotesModal,
    SearchSection,
  },

  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // search
      searchItems: null,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "اقساط الشركات",
          disabled: true,
          href: "",
        },
      ],

      // ========== Statistics
      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "اقساط الشركات",
          number: "10",
        },
      ],

      // ========== dialog Status
      dialogShow: false,
      itemToShow: null,
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Date_Menues
      from_date_menu: false,
      to_date_menu: false,

      // ========== Filter
      filter: {
        name: null,
        safe: [],
        from: null,
        to: null,
      },

      // ========== Model
      model_1: {
        show_model: false,
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,

      // Fixed_data
      safes: ["foo", "bar", "fizz", "buzz"],
      // Custom Modal Data
      modal: {
        descriptionModalIsOpen: false,
        notesModalIsOpen: false,
      },
      selectedItemDesc: null,
      selectedItemNotes: null,
      selectedItemImage: null,
      table_length: null,
      // excel data
      excelData: {
        name: "أقساط الشركات",
        items: null,
        json_fields: {
          "صاحب الاستحقاق": "company",
          المبلغ: {
            field: "amount",
            callback: (value) => {
              return Number(value).toLocaleString();
            },
          },
          العملة: "currency",
          "رقم الشيك": "check_number",
          البنك: "bank",
          "نوع الحساب": "account_type",
          "رقم الحساب": "account_number",
          الإستحقاق: "date",
          الحالة: {
            field: "status",
            callback: (value) => {
              if (value == 0) {
                return "لم يسدد";
              } else if (value == 1) {
                return "سدد";
              } else {
                return "----";
              }
            },
          },
          ملاحظات: "desc",
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        var userType = JSON.parse(this.$store.state.usertype) || null;
        if (
          userType.includes("superadmin") ||
          (userType.includes("company_cash") &&
            userType.includes("confirm_company_cash"))
        ) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "صاحب الاستحقاق",
              value: "company",
              align: "center",
              width: 150,
            },
            {
              text: "المبلغ",
              value: "amount",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "العملة",
              value: "currency",
              align: "center",
              sortable: false,
              width: 50,
            },
            {
              text: "رقم الشيك",
              value: "check_number",
              align: "center",
              sortable: false,
              width: 120,
            },
            {
              text: "صورة الشيك",
              value: "image",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 100,
            },
            {
              text: "البنك",
              value: "bank",
              align: "center",
              width: 100,
            },
            {
              text: "نوع الحساب",
              value: "account_type",
              align: "center",
              width: 100,
            },
            {
              text: "رقم الحساب",
              value: "account_number",
              align: "center",
              width: 130,
              sortable: false,
            },
            {
              text: "الإستحقاق",
              value: "date",
              align: "center",
              width: 100,
            },
            {
              text: "الحالة",
              sortable: false,
              value: "status",
              align: "center",
              width: 90,
            },
            {
              text: "تسديد",
              sortable: false,
              value: "confirm",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
            },
            {
              text: "ملاحظات",
              value: "desc",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "تأكيد",
              value: "revision_done",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
            },
          ];
        } else if (!userType.includes("confirm_company_cash")) {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "صاحب الاستحقاق",
              value: "company",
              align: "center",
              width: 150,
            },
            {
              text: "المبلغ",
              value: "amount",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "العملة",
              value: "currency",
              align: "center",
              sortable: false,
              width: 50,
            },
            {
              text: "رقم الشيك",
              value: "check_number",
              align: "center",
              sortable: false,
              width: 120,
            },
            {
              text: "صورة الشيك",
              value: "image",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 100,
            },
            {
              text: "البنك",
              value: "bank",
              align: "center",
              width: 100,
            },
            {
              text: "نوع الحساب",
              value: "account_type",
              align: "center",
              width: 100,
            },
            {
              text: "رقم الحساب",
              value: "account_number",
              align: "center",
              width: 130,
              sortable: false,
            },
            {
              text: "الإستحقاق",
              value: "date",
              align: "center",
              width: 100,
            },
            {
              text: "الحالة",
              sortable: false,
              value: "status",
              align: "center",
              width: 90,
            },
            {
              text: "تسديد",
              sortable: false,
              value: "confirm",
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
            },
            {
              text: "ملاحظات",
              value: "desc",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "التحكم",
              value: "actions",
              sortable: false,
              align: "center",
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 80,
            },
          ];
        } else {
          return [
            {
              text: "#",
              value: "index",
              sortable: false,
              align: "center",
              width: 10,
            },
            {
              text: "صاحب الاستحقاق",
              value: "company",
              align: "center",
              width: 150,
            },
            {
              text: "المبلغ",
              value: "amount",
              align: "center",
              sortable: false,
              width: 100,
            },
            {
              text: "العملة",
              value: "currency",
              align: "center",
              sortable: false,
              width: 50,
            },
            {
              text: "رقم الشيك",
              value: "check_number",
              align: "center",
              sortable: false,
              width: 120,
            },
            {
              text: "صورة الشيك",
              value: "image",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 100,
            },
            {
              text: "البنك",
              value: "bank",
              align: "center",
              width: 100,
            },
            {
              text: "نوع الحساب",
              value: "account_type",
              align: "center",
              width: 100,
            },
            {
              text: "رقم الحساب",
              value: "account_number",
              align: "center",
              width: 130,
              sortable: false,
            },
            {
              text: "الإستحقاق",
              value: "date",
              align: "center",
              width: 100,
            },
            {
              text: "الحالة",
              sortable: false,
              value: "status",
              align: "center",
              width: 90,
            },
            {
              text: "ملاحظات",
              value: "desc",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "المراجعة",
              value: "review_status",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
            {
              text: "تأكيد",
              value: "revision_done",
              align: "center",
              sortable: false,
              class: "showOnPrint",
              cellClass: "showOnPrint",
              width: 50,
            },
          ];
        }
      }
    },
  },

  methods: {
    // searchTable
    searchTable(items) {
      this.rows = [];
      this.searchItems = items;
      this.setRows();
    },

    // ==================== Start CRUD ====================
    addItem() {
      this.$router.push({ path: `/bank_deposits/${this.id}/add` });
    },
    showItem(item) {
      this.dialogShow = true;
      this.itemToShow = item;
    },
    editItem(item) {
      this.$router.push({ path: `/cach/edit/${item.id}` });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/installment/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/installment/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("deleteSuccess"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // Set Rows
    setRows() {
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/installment",
        params: {
          company_name: this.searchItems ? this.searchItems.company : null,
          from_date: this.searchItems ? this.searchItems.from : null,
          to_date: this.searchItems ? this.searchItems.to : null,
        },
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          this.lodaing = false;
        });
    },
    setItemDesc(desc) {
      this.selectedItemDesc = desc;
    },
    setItemNotes(notes) {
      this.selectedItemNotes = notes;
    },
    showImageModal(image) {
      this.model_1.show_model = true;
      this.selectedItemImage = image;
    },
    closeDescModal() {
      this.modal.descriptionModalIsOpen = false;
    },
    closeNotesModal() {
      this.modal.notesModalIsOpen = false;
    },
    acceptCompany(id) {
      this.lodaing = true;
      this.$axios({
        method: "POST",
        url: "client/installment/accept_installment/" + id,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: "تأكيد بنجاح",
            position: "topRight",
          });
          this.setRows();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  // ======= hooks
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      !userType.includes("superadmin") &&
      !userType.includes("company_cash") &&
      !userType.includes("confirm_company_cash")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.actions2 {
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm_btn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-inline-end: 7px;
    &.accept {
      background-color: #5ab55e;
    }
    &.reject {
      background-color: #f55246;
    }
  }
}
</style>
