import Vue from "vue";
import Vuex from "vuex";
import lang from "./modules/lang/index.js";
import sideNav from "./modules/sideNav/index.js";
import auth from "./modules/Auth/index.js";
import theme from "./modules/Theme/index.js";
import socket from "./modules/SocketGetters/index.js";
import axios from "axios";
import sideBar from "./../locales/ar.json";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    lang_module: lang,
    sideNav_module: sideNav,
    auth_module: auth,
    theme_module: theme,
    socket_module: socket,
  },

  state() {
    return {
      show_filter: false,
      resizeEventExisit: false,
      usertype: localStorage.getItem("Golden_App_User_Type"),
      notification: [],
      unreadnotifications_count: 0,
      side_bar: [],
    };
  },
  actions: {
    get_notification(context) {
      axios.get("/notifications", {
        headers: {
          Authorization: "bearer" + localStorage.getItem("Golden_App_Token"),
        }
      }).then((res) => {
        context.commit("set_notification", res.data.data);
      });
    },
    // get Transh State
    getTranshState(context) {
      let Json_Sidebar = null;
      var userTypes = JSON.parse(localStorage.getItem('Golden_App_User_Type'));
      var screenWidth = window.matchMedia("(max-width: 800px)");
      if (screenWidth.matches && userTypes == "superadmin") {
       Json_Sidebar = JSON.parse(JSON.stringify(sideBar.Mobilesidebar));
      }
      else {
      Json_Sidebar = JSON.parse(JSON.stringify(sideBar.sidebar));
      }
      var theIndex = Json_Sidebar.findIndex(
        (element) => element.title == "المحذوفات"
      );
      var json_items = Json_Sidebar.find((el) => {
        return el.title == "المحذوفات";
      }).items;
      axios.get("trashed_stats").then((res) => {
        // ----------------------------------------------------
        res.data.data.statistic.forEach((item_1) => {
          json_items.forEach((item_2) => {
            if (item_1.title == item_2.statices_items) {
              item_2["unreadNum"] = item_1.data;
            }
          });
        });
        // ------------------------------------------------------
        Json_Sidebar[theIndex].items = json_items;
        Json_Sidebar.forEach((el) => {
          // ======== Check Nested
          let inside_nested = false;
          if (el.items) {
            el.items.forEach((element) => {
              if (element.items) {
                inside_nested = true;
              }
            });
          }
          // ======== IF Nested Else
          if (inside_nested) {
            el.items.forEach((subEl) => {
              if (
                subEl.routeLinks?.includes(window.rootRouter.app._route.fullPath || window.rootRouter.app._route.name)
              ) {
                subEl.active = true;
              } else {
                subEl.active = false;
              }
            });
            if (el.routeLinks?.includes(window.rootRouter.app._route.fullPath || window.rootRouter.app._route.name)) {
              el.active = true;
            } else {
              el.active = false;
            }
          } else {
            if (el.routeLinks?.includes(window.rootRouter.app._route.fullPath|| window.rootRouter.app._route.name)) {
              el.active = true;
            } else {
              el.active = false;
            }
          }
        });
        // -------------------------------------------------------
        context.commit("update_sidebar", Json_Sidebar);
      });
    },
  },
  getters: {
    resizeEventExisit(state) {
      return state.resizeEventExisit;
    },
    notification(state) {
      return state.notification;
    },
    new_side_bar(state) {
      return state.side_bar;
    },
  },
  mutations: {
    changeRessizeEvent(state) {
      state.resizeEventExisit = !state.resizeEventExisit;
    },
    openFilter(state) {
      state.show_filter = true;
    },
    closeFilter(state) {
      state.show_filter = false;
    },
    set_notification(state, payload) {
      state.notification = payload.notifications;
      state.unreadnotifications_count = payload.unreadnotifications_count;
    },
    update_sidebar(state, payload) {
      var userTypes = JSON.parse(localStorage.getItem('Golden_App_User_Type'));
      state.side_bar = payload;
      state.side_bar = state.side_bar.filter((ele) => {
        if (ele.global) {
          return true;
        }
        var boolResult = false;
        if (ele.userType) {
          ele.userType.forEach((item) => {
            if (userTypes.includes(item)) {
              boolResult = true;
            }
          });
          return boolResult;
        }
      });
      state.side_bar.forEach((subEle) => {
        if (
          userTypes.includes("confirm_lock_and_deals") &&
          !userTypes.includes("lock_and_deals")
        ) {
          if (subEle.items) {
            subEle.items = subEle.items.filter((item) => {
              return !item.hideOnConfirm;
            });
          }
        }
      });
      //  ------------------------------------------------------------------
    },
  },
});
