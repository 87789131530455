<template>
  <div class="contact_message">
    <div class="container">
      <!-- Start Breadcrumb -->
      <!-- <Breadcrumb :title="$t('contactMessages.title')" :items="items" /> -->
      <!--Start Statistics Card-->
      <div class="row mb-5">
        <div class="col-md-3" v-for="item in statisticsItems" :key="item.id">
          <stats-card
            :icon="item.icon"
            :title="item.desc"
            :number="item.title"
            :color="current_theme == 'dark'? '#525e6e':'#acc4d9'
            :bgColor="'transparent'"
          ></stats-card>
        </div>
      </div>
      <!--End Statistics Card-->
      <div class="row">
        <div class="col-sm-12">
          <!-- START:: QUESTION REPLAY MESSAGE -->
          <div class="custom_card">
            <!-- START:: CARD TITLE -->
            <div class="card-header">
              <h4 class="card-title">{{ $t("contactMessages.messages") }}</h4>
            </div>
            <!-- END:: CARD TITLE -->
            <!--START:: MESSAGES-->
            <ul class="messages">
              <!--START:: SENDER MESSAGE-->
              <li class="sender__message">
                <div class="image">
                  <img src="@/assets/images/people/profile.jpg" />
                </div>
                <div class="content__message">
                  <p>
                    هذا النص هو مثال لنص يمكن ان يتم اسبتداله بنص اخر في نفس
                    المساحة
                  </p>
                </div>
              </li>
              <!--END:: SENDER MESSAGE-->
              <!--START:: RECIVER MESSAGE-->
              <li class="reciver__message">
                <div class="image">
                  <img src="@/assets/images/people/profile.jpg" />
                </div>
                <div class="content__message">
                  <p>
                    هذا النص هو مثال لنص يمكن ان يتم اسبتداله بنص اخر في نفس
                    المساحة
                  </p>
                </div>
              </li>
              <!--END:: RECIVER MESSAGE-->
            </ul>
            <!--END:: MESSAGES-->
          </div>
          <!-- END:: QUESTION REPLAY MESSAGE -->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <!-- START:: ADMIN REPLAY MESSAGE -->
          <div class="custom_card">
            <!-- START:: CARD TITLE -->
            <div class="card-header">
              <h4 class="card-title">{{ $t("contactMessages.Replay") }}</h4>
            </div>
            <!-- END:: CARD TITLE -->

            <!-- START:: CREATE FORM WRAPPER -->
            <form @submit.prevent="replayMessage">
              <div class="container">
                <div class="row justify-content-between">
                  <!-- START:: INPUT WRAPPER -->
                  <div class="col-lg-12 py-0">
                    <!-- START:: TEXT AREA -->
                    <div class="input_wrapper top_label">
                      <label for="desc" class="form-label">{{
                        $t("contactMessages.data.replay")
                      }}</label>
                      <textarea
                      v-on:keydown.enter.prevent="validateCreateForm"
                        class="form-control"
                        id="desc"
                        v-model.trim="data.replay"
                        rows="5"
                      ></textarea>
                    </div>
                    <!-- END:: TEXT AREA -->
                  </div>
                  <!-- END:: INPUT WRAPPER -->
                </div>
              </div>

              <div class="buttons_wrapper">
                <!-- START:: BUTTON -->
                <button class="button_style_1" :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading">
                  {{ $t("Forms.submit") }}
                  <span class="btn_loader" v-if="btnIsLoading"></span>
                </button>
                <!-- END:: BUTTON -->
              </div>
            </form>
            <!-- END:: CREATE FORM WRAPPER -->
          </div>
          <!-- END:: ADMIN REPLAY MESSAGE -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  name: "Replay",

  data() {
    return {
      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: DATA
      data: {
        replay: "",
      },
      // END:: DATA

      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-user",
          title: "الاسم",
          desc: "احمد على",
        },
        {
          id: 2,
          icon: "fal fa-envelope-open-text",
          title: "البريد الالكتروني",
          desc: "admin@info.com",
        },
        {
          id: 3,
          icon: "fal fa-phone",
          title: "رقم الهاتف",
          desc: "0102459666",
        },
        {
          id: 4,
          icon: "fal fa-user",
          title: "نوع المرسل",
          desc: "طالب",
        },
      ],
    };
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
  },
  methods: {
    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE REPLAY METHOD
    replayMessage() {
      if (!this.btnIsLoading) {
      this.btnIsLoading = true;

      if (!this.data.replay) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("contactMessages.validation.replay"),
          position: "topRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.$iziToast.success({
          timeout: 2000,
          message: this.$t("global.sendSuccess"),
          position: "topRight",
        });
        this.$router.push({ path: "/contactMessages" });
        this.btnIsLoading = false;
        return;
      }
      }
    },
    // END:: VALIDATE REPLAY METHOD
  },
  components: {
    StatsCard,
  },
};
</script>

<style></style>
