<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->
    <div class="custom_card">
      <!-- START:: CARD TITLE -->
      <div class="card-header">
        <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
      </div>
      <!-- END:: CARD TITLE -->

      <!-- START:: CREATE FORM WRAPPER -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-12 py-0">
              <!-- START:: TITLE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="name_input_ar"
                  @input="checkIfInputIsEmpty"
                  v-model.trim="updateData.nameAr"
                />
                <label for="name_input_ar" class="form-label">{{
                  $t("jobs.data.nameAr")
                }}</label>
              </div>
              <!-- END:: TITLE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="name_input_en"
                  @input="checkIfInputIsEmpty"
                  v-model.trim="updateData.nameEn"
                />
                <label for="name_input_en" class="form-label">{{
                  $t("jobs.data.nameEn")
                }}</label>
              </div>
            </div> -->
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-12 py-0">
              <!-- START:: TEXT AREA -->
              <div class="input_wrapper top_label">
                <label for="desc_ar" class="form-label">{{
                  $t("jobs.data.descAr")
                }}</label>
                <textarea
                  class="form-control"
                  id="desc_ar"
                  v-model.trim="updateData.descAr"
                  rows="5"
                ></textarea>
              </div>
              <!-- END:: TEXT AREA -->
            </div>
            <!-- START:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="desc_en" class="form-label">{{
                  $t("jobs.data.descEn")
                }}</label>
                <textarea
                  class="form-control"
                  id="desc_en"
                  v-model.trim="updateData.descEn"
                  rows="5"
                ></textarea>
              </div>
            </div> -->
            <!-- START:: INPUT WRAPPER -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <!-- START:: BUTTON -->
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("Forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
          <!-- END:: BUTTON -->
        </div>
      </form>
      <!-- END:: CREATE FORM WRAPPER -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  props: ["id"],
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("jobs.title"),
          disabled: false,
          href: "/allJobs",
        },
        {
          text: this.$t("edit"),
          disabled: true,
          href: "",
        },
      ],

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: SINGLE SELECT DATA
      single_select_options: [
        {id: 1, name: "طالب"},
        {id: 2, name: "ولي امر"},
        {id: 3, name: "معلم"},
        {id: 4, name: "عام"},
      ],
      // END:: SINGLE SELECT DATA

      // START:: CREATE DATA
      updateData: {
        nameAr: null,
        nameEn: null,
        descAr: null,
        descEn: null,
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.updateData.avatar = obj;
    },
    // END:: Uplode Avatar

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: GET DATA
    getData() {
      this.$axios.get("client/position/" + this.id).then((res) => {
        this.updateData.nameAr = res.data.data.name_ar;
        this.updateData.nameEn = res.data.data.name_en;
        this.updateData.descAr = res.data.data.desc_ar;
        this.updateData.descEn = res.data.data.desc_en;
      });
    },
    // END:: GET DATA

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.updateData.nameAr) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("jobs.validation.nameAr"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.updateData.nameEn) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("jobs.validation.nameEn"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.btnIsLoading = true;
          const data = {
            ar: {
              name: this.updateData.nameAr,
              desc: this.updateData.descAr,
            },
            en: {
              name: this.updateData.nameAr,
              desc: this.updateData.descAr,
            },
          };
          this.$axios
            .put("client/position/" + this.id, data)
            .then(() => {
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("global.editSuccess"),
                position: "topRight",
              });
              this.$router.push({path: "/allJobs"});
              this.btnIsLoading = false;
              this.$router.push({path: "/allJobs"});
            })
            .catch((err) => {
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "topRight",
              });
              this.btnIsLoading = false;
            });
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
  created() {
    this.getData();
  },
};
</script>
