import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import iziToast from "izitoast";
// ============================== Start Views ==============================
import Home from "../views/Home.vue"; // Home

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start Countries ==========
import CountriesHome from "../views/children/Countries/Home.vue";
// Show All
import AllCountries from "../views/children/Countries/ShowAll.vue";
// Add
import AddCountry from "../views/children/Countries/Add.vue";
// Edit
import EditCountry from "../views/children/Countries/Edit.vue";
// Trash
import CountryTrash from "../views/children/Countries/Trash.vue";
// ========== End Countries ==========

// ========== Start Enterprice Owners ==========
import EnterpriceOwners from "../views/children/EnterpriceOwners/Home.vue";
// Show All
import AllEnterpriceOnwers from "../views/children/EnterpriceOwners/ShowAll.vue";
// Add
import AddEnterpriceOwner from "../views/children/EnterpriceOwners/Add.vue";
// Edit
import EditEnterpriceOwner from "../views/children/EnterpriceOwners/Edit.vue";
// Show
import ShowEnterpriceOwner from "../views/children/EnterpriceOwners/Show.vue";
// Trash
import EnterpriceOwnersTrash from "../views/children/EnterpriceOwners/Trash.vue";
// ========== End Enterprice Owners ==========

// ========== Start Enterprices ==========
import Enterprices from "../views/children/Enterprices/Home.vue";
// Show All
import AllEnterprices from "../views/children/Enterprices/ShowAll.vue";
// Add
import AddEnterprice from "../views/children/Enterprices/Add.vue";
// Edit
import EditEnterprice from "../views/children/Enterprices/Edit.vue";
// Show
import ShowEnterprice from "../views/children/Enterprices/Show.vue";
// Trash
import EnterpriceTrash from "../views/children/Enterprices/Trash.vue";
// ========== End Enterprices ==========

// ========== Start Enterprice Owners ==========
import EnterpriceAccounts from "../views/children/EnterpriceAccount/Home.vue";
// Show All
import AllEnterpriceAccounts from "../views/children/EnterpriceAccount/ShowAll.vue";
import AllEnterpriceReports from "../views/children/EnterpriceAccount/enterprice-report.vue";
// Add
import AddEnterpriceAccount from "../views/children/EnterpriceAccount/Add.vue";
// Edit
import EditEnterpriceAccount from "../views/children/EnterpriceAccount/Edit.vue";
// Show
import ShowEnterpriceAccount from "../views/children/EnterpriceAccount/Show.vue";
import EnterpriceActions from "../views/children/enterpriceActions/showall.vue";
import EnterpriceActionsHome from "../views/children/enterpriceActions/Home.vue";
import EnterpriceActionsTrash from "../views/children/enterpriceActions/Trash.vue";
// Trash
import EnterpriceAccountTrash from "../views/children/EnterpriceAccount/Trash.vue";
// ========== End Enterprice Owners ==========

// ========== Start Enterprice Credits ==========
import EnterpriceCredits from "../views/children/EnterpriceCredits/Home.vue";
// Show All
import AllEnterpriceCredits from "../views/children/EnterpriceCredits/ShowAll.vue";
// Add
import AddEnterpriceCredit from "../views/children/EnterpriceCredits/Add.vue";
// Edit
import EditEnterpriceCredit from "../views/children/EnterpriceCredits/Edit.vue";
// Show
import ShowEnterpriceCredit from "../views/children/EnterpriceCredits/Show.vue";
// Trash
import EnterpriceCreditTrash from "../views/children/EnterpriceCredits/Trash.vue";
//Not Found
import NotFoundEnterpriceCredit from "../views/children/EnterpriceCredits/NotFound.vue";
//Not Found Trash
import EnterpriceCreditNotFoundTrash from "../views/children/EnterpriceCredits/NotFoundTrash.vue";
//Found
import FoundEnterpriceCredit from "../views/children/EnterpriceCredits/found.vue";
//  FoundTrash
import EnterpriceCreditFoundTrash from "../views/children/EnterpriceCredits/FoundTrash.vue";
// ========== End Enterprice Credits ==========
// ========== Start Enterprice ATM ==========
import EnterpriceATM from "../views/children/EnterpriceATM/Home.vue";
// Show All
import AllEnterpriceATM from "../views/children/EnterpriceATM/ShowAll.vue";
// Add
import AddEnterpriceATM from "../views/children/EnterpriceATM/Add.vue";
// Edit
import EditEnterpriceATM from "../views/children/EnterpriceATM/Edit.vue";
// Show
import ShowEnterpriceATM from "../views/children/EnterpriceATM/Show.vue";
// Trash
import EnterpriceATMTrash from "../views/children/EnterpriceATM/Trash.vue";
//Not Found
import NotFoundEnterpriceATM from "../views/children/EnterpriceATM/NotFound.vue";
//Not Found Trash
import EnterpriceATMNotFoundTrash from "../views/children/EnterpriceATM/NotFoundTrash.vue";
//Found
import FoundEnterpriceATM from "../views/children/EnterpriceATM/found.vue";
//  FoundTrash
import EnterpriceATMFoundTrash from "../views/children/EnterpriceATM/FoundTrash.vue";
// ========== End Enterprice ATM ==========
// ========== Start Bills ==========
import BillsHome from "../views/children/bills/Home.vue";
// Show All
import AllBills from "../views/children/bills/showall.vue";
// Add
import AddBills from "../views/children/bills/Add.vue";
// ========== End Bills ==========
// ========== Start Debits ==========
import DebitsHome from "../views/children/debits/Home.vue";
// Show All
import AllDebits from "../views/children/debits/ShowAll.vue";
// Add
import AddDebit from "../views/children/debits/Add.vue";
// Edit
import EditDebit from "../views/children/debits/Edit.vue";
// ========== End Debits ==========

// ========== Company Transfers ==========
import CompanyTransfersHome from "../views/children/CompanyTransfers/Home.vue";
// Show All
import AllCompnayTransfers from "../views/children/CompanyTransfers/ShowAll.vue";
// Add
import AddCompanyTransfer from "../views/children/CompanyTransfers/Add.vue";
// Edit
import EditCompanyTransfer from "../views/children/CompanyTransfers/Edit.vue";
// Trash
import CompanyTransferTrash from "../views/children/CompanyTransfers/Trash.vue";
// ========== Company Transfers ==========
// ========== Company Actions ==========
import CompanyActionHome from "../views/children/CompanyAction/Home.vue";
// Show All
import AllCompnayAction from "../views/children/CompanyAction/ShowAll.vue";
// Add
import AddCompanyAction from "../views/children/CompanyAction/Add.vue";
// Edit
import EditCompanyAction from "../views/children/CompanyAction/Edit.vue";
// Trash
import CompanyActionTrash from "../views/children/CompanyAction/Trash.vue";
// ========== Company Actions ==========
// ========== Start Products ==========
import ProductsHome from "../views/children/Products/Home.vue";
// Show All
import AllProducts from "../views/children/Products/ShowAll.vue";
// Add
import AddProduct from "../views/children/Products/Add.vue";
// Edit
import EditProduct from "../views/children/Products/Edit.vue";
// Trash
import ProductTrash from "../views/children/Products/Trash.vue";
// ========== End Debits ==========
// ========== Start sub Products ==========
// Show All
import AllProductsSub from "../views/children/subProducts/ShowAll.vue";
// Add
import AddProductSub from "../views/children/subProducts/Add.vue";
// Edit
import EditProductSub from "../views/children/subProducts/Edit.vue";
// Trash
import ProductSubTrash from "../views/children/subProducts/Trash.vue";
// ========== End Debits ==========
// ========== Start Employess ==========
import EmployeesHome from "../views/children/Employees/Home.vue";
// Show All
import AllEmployees from "../views/children/Employees/ShowAll.vue";
// Add
import AddEmployee from "../views/children/Employees/Add.vue";
// Edit
import EditEmployee from "../views/children/Employees/Edit.vue";
// Trash
import EmployeeTrash from "../views/children/Employees/Trash.vue";
// ========== End Employess ==========

// ========== Start Deposits ==========
import DepositsHome from "../views/children/Deposits/Home.vue";
// Show All
import AllDeposits from "../views/children/Deposits/ShowAll.vue";
// Add
import AddDeposit from "../views/children/Deposits/Add.vue";
// Edit
import EditDeposit from "../views/children/Deposits/Edit.vue";
// Trash
import DepositTrash from "../views/children/Deposits/Trash.vue";
// Trash report
import DepositTrashReport from "../views/children/Deposits/TrashReport.vue";
// ========== End Deposits ==========

// ========== Start Bank_deposits ==========
import Bank_depositsHome from "../views/children/bank_deposits/Home.vue";
// Show All
import AllBank_deposits from "../views/children/bank_deposits/ShowAll.vue";
// Add
import AddBank_deposit from "../views/children/bank_deposits/Add.vue";
// Edit
import EditBank_deposit from "../views/children/bank_deposits/Edit.vue";
// ========== End Bank_deposits ==========

// ========== Start Withdraws ==========
import WithdrawsHome from "../views/children/Withdraws/Home.vue";
// Show All
import AllWithdraws from "../views/children/Withdraws/ShowAll.vue";
// Add
import AddWithdraw from "../views/children/Withdraws/Add.vue";
// Edit
import EditWithdraw from "../views/children/Withdraws/Edit.vue";
// Trash
import WithdrawTrash from "../views/children/Withdraws/Trash.vue";
// Trash report
import WithdrawTrashReport from "../views/children/Withdraws/TrashReport.vue";
// ========== End Withdraws ==========

// ========== Start Bank_withdraws ==========
import Bank_withdrawsHome from "../views/children/bank_withdraws/Home.vue";
// Show All
import AllBank_withdraws from "../views/children/bank_withdraws/ShowAll.vue";
// Add
import AddBank_withdraw from "../views/children/bank_withdraws/Add.vue";
// Edit
import EditBank_withdraw from "../views/children/bank_withdraws/Edit.vue";
// ========== End Bank_withdraws ==========

// ========== Start client_accounts ==========
import Client_accountsHome from "../views/children/client_accounts/Home.vue";
// Show All
import AllClient_accounts from "../views/children/client_accounts/ShowAll.vue";

// Add
import AddClient_account from "../views/children/client_accounts/Add.vue";
// Trash
import Client_accountsTrash from "../views/children/client_accounts/Trash.vue";
// Edit
// import EditClient_account from "../views/children/client_accounts/Edit.vue";
// ========== End client_accounts ==========

// ========== Start Client Commission ==========
import ClientCommissionHome from "../views/children/ClientCommission/Home.vue";
// Show All
import AllClientCommission from "../views/children/ClientCommission/ShowAll.vue";
// Add
// Edit
// ========== End Client Commission ==========

// ========== Start Bank_transfares ==========
import Bank_transfaresHome from "../views/children/bank_transfares/Home.vue";
// Show All
import AllBank_transfares from "../views/children/bank_transfares/ShowAll.vue";
// Add
import AddBank_transfare from "../views/children/bank_transfares/Add.vue";
// Trash
import BankTransfareTrash from "../views/children/bank_transfares/Trash.vue";
// Edit
// import EditClient_account from "../views/children/client_accounts/Edit.vue";
// ========== End Bank_transfares ==========

// ========== Start Banks ==========
import BanksHome from "../views/children/Banks/Home.vue";
// Show All
import AllBanks from "../views/children/Banks/ShowAll.vue";
// Add
import AddBank from "../views/children/Banks/Add.vue";
// Edit
import EditBank from "../views/children/Banks/Edit.vue";
// Trash
import BanksTrash from "../views/children/Banks/Trash.vue";
// ========== End Banks ==========

// ========== Start Export Banks ==========
import ExportBanksHome from "../views/children/ExportBanks/Home.vue";
// Show All
import AllExportBanks from "../views/children/ExportBanks/ShowAll.vue";
// Add
import AddExportBank from "../views/children/ExportBanks/Add.vue";
// Edit
import EditExportBank from "../views/children/ExportBanks/Edit.vue";
// Trash
import ExportBankTrash from "../views/children/ExportBanks/Trash.vue";
// ========== End Export Banks ==========

// ========== Start Export Banks ==========
import ExportRefrencesHome from "../views/children/ExportRefrences/Home.vue";
// Show All
import AllExportRefrences from "../views/children/ExportRefrences/ShowAll.vue";
// Add
import AddExportRefrence from "../views/children/ExportRefrences/Add.vue";
// Edit
import EditExportRefrence from "../views/children/ExportRefrences/Edit.vue";
// Trash
import ExportRefrenceTrash from "../views/children/ExportRefrences/Trash.vue";
// ========== End Export Banks ==========

// ========== Start Export Banks ==========
import ExportAppHome from "../views/children/ExportApp/Home.vue";
// Show All
import AllExportApp from "../views/children/ExportApp/ShowAll.vue";
// Add
import AddExportApp from "../views/children/ExportApp/Add.vue";
// Edit
import EditExportApp from "../views/children/ExportApp/Edit.vue";
// Trash
import ExportAppTrash from "../views/children/ExportApp/Trash.vue";

import ExportCount from "../views/children/ExportApp/count.vue";
// ========== End Export Banks ==========

// ========== Start Export Banks ==========
import ExportClientHome from "../views/children/ExportClient/Home.vue";
// Show All
import AllExportClient from "../views/children/ExportClient/ShowAll.vue";
// Add
import AddExportClient from "../views/children/ExportClient/Add.vue";
// Edit
import EditExportClient from "../views/children/ExportClient/Edit.vue";
// Trash
import ExportClientTrash from "../views/children/ExportClient/Trash.vue";
// ========== End Export Banks ==========

// ========== Start Ports And Import Files ==========
// ******************** Import Company Home ********************
import ImportCompaniesHome from "../views/children/PortsAndImportFiles/ImportCompanies/Home.vue";
// ******************** Show All Import Companies ********************
import AllImportCompanies from "../views/children/PortsAndImportFiles/ImportCompanies/ShowAll.vue";
// ******************** Add Import Company ********************
import AddImportCompany from "../views/children/PortsAndImportFiles/ImportCompanies/Add.vue";
// ******************** Edit Import Company ********************
import EditImportCompany from "../views/children/PortsAndImportFiles/ImportCompanies/Edit.vue";
// ******************** Import Companies Trash ********************
import ImportCompaniesTrash from "../views/children/PortsAndImportFiles/ImportCompanies/Trash.vue";

// ============================================= //
// ============================================= //

// ******************** Ports Home ********************
import PortsHome from "../views/children/PortsAndImportFiles/Ports/Home.vue";
// ******************** Show All Import Companies ********************
import AllPorts from "../views/children/PortsAndImportFiles/Ports/ShowAll.vue";
// ******************** Add Port ********************
import AddPort from "../views/children/PortsAndImportFiles/Ports/Add.vue";
// ******************** Edit Port ********************
import EditPort from "../views/children/PortsAndImportFiles/Ports/Edit.vue";
// ******************** Ports Trash ********************
import PortTrash from "../views/children/PortsAndImportFiles/Ports/Trash.vue";

// ============================================= //
// ============================================= //

// ******************** Import File Home ********************
import ImportFilesHome from "../views/children/PortsAndImportFiles/ImportFiles/Home.vue";
// ******************** Show All Import Files ********************
import AllImportFiles from "../views/children/PortsAndImportFiles/ImportFiles/ShowAll.vue";
// ******************** Add Import File ********************
import AddImportFile from "../views/children/PortsAndImportFiles/ImportFiles/Add.vue";
// ******************** Edit Import File ********************
import EditImportFile from "../views/children/PortsAndImportFiles/ImportFiles/Edit.vue";
// ******************** Import Filies Trash ********************
import ImportFilesTrash from "../views/children/PortsAndImportFiles/ImportFiles/Trash.vue";
// signle 
import ImportFilesSingle from "../views/children/PortsAndImportFiles/ImportFiles/single.vue";

// ========== End Ports And Import Files ==========

// ========== Start Banks Dubai ==========
import BanksDubaiHome from "../views/children/Banks/dubai/Home.vue";
// Show All
import AllBanksDubai from "../views/children/Banks/dubai/ShowAll.vue";
// Add
import AddBankDubai from "../views/children/Banks/dubai/Add.vue";
// Edit
import EditBankDubai from "../views/children/Banks/dubai/Edit.vue";
// Trash
import BanksDubaiTrash from "../views/children/Banks/dubai/Trash.vue";
// reports
import BanksDubaiReports from "../views/children/Banks/dubai/report.vue";
// account
import BankDubaiAccount from "../views/children/Banks/dubai/account.vue";
import BankDubaiAccountTrach from "../views/children/Banks/dubai/accountTrach.vue";
// amount
import BanksDubaiHomeAmount from "../views/children/Banks/dubai/amount/Home.vue";
import AllBanksDubaiAmount from "../views/children/Banks/dubai/amount/ShowAll.vue";
import AddBankDubaiAmount from "../views/children/Banks/dubai/amount/Add.vue";
import EditBankDubaiAmount from "../views/children/Banks/dubai/amount/Edit.vue";
import BanksDubaiAmountTrash from "../views/children/Banks/dubai/amount/Trash.vue";
// withdraw
import BanksDubaiHomeWithdraw from "../views/children/Banks/dubai/withdraw/Home.vue";
import AllBanksDubaiWithdraw from "../views/children/Banks/dubai/withdraw/ShowAll.vue";
import AddBankDubaiWithdraw from "../views/children/Banks/dubai/withdraw/Add.vue";
import EditBankDubaiWithdraw from "../views/children/Banks/dubai/withdraw/Edit.vue";
import BanksDubaiWithdrawTrash from "../views/children/Banks/dubai/withdraw/Trash.vue";
// ========== End Banks Dubai ==========

// ========== Start Clients ==========
import ClientsHome from "../views/children/Clients/Home.vue";
// Show All
import AllClients from "../views/children/Clients/ShowAll.vue";
import AllClient_amount from "../views/children/Clients/clients-amount.vue";
// Add
import AddClient from "../views/children/Clients/Add.vue";
// Edit
import EditClient from "../views/children/Clients/Edit.vue";
// Trash
import ClientTrash from "../views/children/Clients/Trash.vue";
//clients reports
import ClientsReport from "../views/children/Clients/clients-report";
// ===== Show Clinet
import ShowClientHome from "../views/children/Clients/Show/Home.vue";
import ShowClientDetails from "../views/children/Clients/Show/Details.vue";
import ShowClientInfo from "../views/children/Clients/Show/Info.vue";

// ========== End Clients ==========

// ========== Start Service_operations ==========
import Service_operationsHome from "../views/children/service_operations/Home.vue";
// Show All
import AllService_operations_deposit from "../views/children/service_operations/ShowAllDeposit.vue";
import AllService_operations_withdraw from "../views/children/service_operations/ShowAllWithdraw.vue";
import AllService_operations_reports from "../views/children/service_operations/reports.vue";
// Add
import AddService_operation_withdraw from "../views/children/service_operations/Add_withdraw.vue";
import AddService_operation_deposit from "../views/children/service_operations/Add_deposit.vue";
// Edit
import EditService_operation from "../views/children/service_operations/Edit.vue";
// Trash
import ServiceOperationsDepositTrash from "../views/children/service_operations/DepositTrash.vue";
import ServiceOperationsWithdrawTrash from "../views/children/service_operations/WithdrawTrash.vue";
// ========== End Service_operations ==========

// ========== Start Bank_operations ==========
// Show All
import Bank_operationsHome from "../views/children/bank_deposits/Home.vue";
import AllBank_operations_deposits from "../views/children/bank_deposits/ShowAll.vue";
import AllBank_operations_withdraws from "../views/children/bank_withdraws/ShowAll.vue";
// Add
import AddBank_operation_withdraw from "../views/children/bank_withdraws/Add.vue";
import AddBank_operation_deposit from "../views/children/bank_deposits/Add.vue";
// Edit
import EditBank_operation_withdraw from "../views/children/bank_withdraws/Edit.vue";
import EditBank_operation_deposit from "../views/children/bank_deposits/Edit.vue";
// Trash
import BankOperationDepositTrash from "../views/children/bank_deposits/Trash.vue";
import BankOperationWithdrawTrash from "../views/children/bank_withdraws/Trash.vue";
// ========== End Bank_operations ==========

// ========== Start Currency ==========
import CurrencyHome from "../views/children/Currency/Home.vue";
// Show All
import AllCurrency from "../views/children/Currency/ShowAll.vue";
// Add
import AddCurrency from "../views/children/Currency/Add.vue";
// Edit
import EditCurrency from "../views/children/Currency/Edit.vue";
// Trash
import CurrencyTrash from "../views/children/Currency/Trash.vue";

// *********** Buy Currency
// Show All
import AllBuyCurrencies from "../views/children/Currency/BuyCurrency/ShowAll.vue";
// Buy Currency
import BuyCurrency from "../views/children/Currency/BuyCurrency/BuyCurrency.vue";
// Edit Buy Currency
import EditBuyOperation from "../views/children/Currency/BuyCurrency/EditBuyOperation.vue";
// Buy Currency Trash
import BuyCurrencyTrash from "../views/children/Currency/BuyCurrency/BuyCurrencyTrash.vue";

// *********** Sale Currency
// Show All
import AllSaleCurrencies from "../views/children/Currency/SaleCurrency/ShowAll.vue";
// Sale Currency
import SaleCurrency from "../views/children/Currency/SaleCurrency/SaleCurrency.vue";
// Edit Sale Currency
import EditSaleOperation from "../views/children/Currency/SaleCurrency/EditSaleOperation.vue";
// Sale Currency Trash
import SaleCurrencyTrash from "../views/children/Currency/SaleCurrency/SaleCurrencyTrash.vue";

// *********** Transfer Currency
// Show All
import AllTransferCurrencies from "../views/children/Currency/TransferCurrency/ShowAll.vue";
// Transfer Currency
import TransferCurrency from "../views/children/Currency/TransferCurrency/TransferCurrency.vue";
// Edit Transfer Currency
import EditTransferOperation from "../views/children/Currency/TransferCurrency/EditTransferOperation.vue";
//  Transfer Currency Trash
import TransferCurrencyTrash from "../views/children/Currency/TransferCurrency/TransferCurrencyTrash.vue";

// ========== Start Expenses ==========
import ExpensesHome from "../views/children/Expenses/Home.vue";
// Show All
import AllExpenses from "../views/children/Expenses/ShowAll.vue";
// Add
import addExpenses from "../views/children/Expenses/Add.vue";
// Edit
import editExpenses from "../views/children/Expenses/Edit.vue";
// Trash
import ExpensesTrash from "../views/children/Expenses/Trash.vue";

// ========== Start Deliverables ==========
import DeliverablesHome from "../views/children/Deliverables/Home.vue";
// Show All
import AllDeliverables from "../views/children/Deliverables/ShowAll.vue";
// Add
import addDeliverables from "../views/children/Deliverables/Add.vue";
// Edit
import editDeliverables from "../views/children/Deliverables/Edit.vue";

// ========== Start locks ==========
import LocksHome from "../views/children/Locks/Home.vue";
// Show All
import AllLocks from "../views/children/Locks/ShowAll.vue";
import LockAmount from "../views/children/Locks/LockAmount.vue";
// Show All
import DeleteLocks from "../views/children/Locks/delete_lock.vue";
// Add
import addLocks from "../views/children/Locks/Add.vue";
import editLock from "../views/children/Locks/edit.vue";
import LocksTrash from "../views/children/Locks/LocksTrash.vue";
// Add currency
import addLockCurrency from "../views/children/Locks/Add-currency.vue";
// Lock Currencies
import LockCurrencies from "../views/children/Locks/LockCurrencies.vue";
// Lock Balances
import LockBalances from "../views/children/Locks/LockBalances.vue";
// Lock Movement
import LockMovement from "../views/children/Locks/LockMovement/LockMovement.vue";
// All Transfer Balances
import AllTransferBalances from "../views/children/Locks/TransferBalances/ShowAll.vue";
// Add Transfer Balances
import AddTransferBalances from "../views/children/Locks/TransferBalances/Add.vue";
// Edit Transfer Balances
import EditTransferBalances from "../views/children/Locks/TransferBalances/Edit.vue";
// Transfer Balances Trash
import TransferBalancesTrash from "../views/children/Locks/TransferBalances/TransferBalancesTrash.vue";
// ========== End locks ==========

// ========== Start Inbox ==========
import InboxHome from "../views/children/Inbox/Home.vue";
// Inbox Balances
import InboxBalances from "../views/children/Inbox/InboxBalances.vue";
// Inbox Movement
import InboxMovement from "../views/children/Inbox/InboxMovement/InboxMovement.vue";
// All Transfer Balances
import AllTransferBalances_inbox from "../views/children/Inbox/TransferBalances/ShowAll.vue";
// Add Transfer Balances
import AddTransferBalances_inbox from "../views/children/Inbox/TransferBalances/Add.vue";
// Edit Transfer Balances
import EditTransferBalances_inbox from "../views/children/Inbox/TransferBalances/Edit.vue";
// ========== End Inbox ==========

// ========== Start Categories ==========
import CategoriesHome from "../views/children/Categories/Home.vue";
// Show All
import AllCategories from "../views/children/Categories/ShowAll.vue";
// Add
import AddCategories from "../views/children/Categories/Add.vue";
// Edit
import EditCategories from "../views/children/Categories/Edit.vue";
// Trash
import CategoriesTrash from "../views/children/Categories/Trash.vue";
// ========== End Main Slider ==========

// ========== Start Privillages ==========
import PrivillagesHome from "../views/children/Privillages/Home.vue";
// Show All
import AllPrivillages from "../views/children/Privillages/ShowAll.vue";
// Add
import AddPrivillage from "../views/children/Privillages/Add.vue";
// Edit
import EditPrivillage from "../views/children/Privillages/Edit.vue";
// Trash
import PrivillageTrash from "../views/children/Privillages/Trash.vue";
// ========== End Privillages ==========

// ========== Safe Transactions
// Show All
import AllSafeTransactions from "../views/children/SafeTransactions/AllSafeTransactions.vue";
// Add
import AddSafeTransactions from "../views/children/SafeTransactions/AddSafeTransactions.vue";
// Edit
import EditSafeTransactions from "../views/children/SafeTransactions/EditSafeTransactions.vue";

// ========== Comunities
// Show All
import AllComunities from "../views/children/Comunities/ShowAll.vue";
// Add Comunity
import AddComunity from "../views/children/Comunities/AddComunity.vue";
// Edit Comunity
import EditComunity from "../views/children/Comunities/EditComunity.vue";

// ========== Companies
// Show All CompaniesWaiting
import AllCompanies from "../views/children/Companies/ShowAll.vue";
import CompaniesWaiting from "../views/children/Companies/companies_waiting.vue";
// Add Company
import AddCompany from "../views/children/Companies/AddCompany.vue";
// Edit Company
import EditCompany from "../views/children/Companies/EditCompany.vue";
// Show Company
import ShowCompany from "../views/children/Companies/ShowSingle.vue";
// Trash
import CompanyTrash from "../views/children/Companies/Trash.vue";
import WaitingCompanyTrash from "../views/children/Companies/CompaniesWaitingTrash.vue";

// Show All CompaniesCachs
import CompaniesCachs from "../views/children/CompaniesCach/Home.vue";
import AllCompaniesCachs from "../views/children/CompaniesCach/ShowAll.vue";
// Add Company
import AddCompaniesCachs from "../views/children/CompaniesCach/Add.vue";
// Edit Company
import EditCompaniesCachs from "../views/children/CompaniesCach/Edit.vue";
// Tash
import CompaniesCachsTrash from "../views/children/CompaniesCach/Trash.vue";
// ========== Customers
//Show All
import Customers from "../views/children/Customers/ShowAll.vue";
//Add Customer
import AddCustomer from "../views/children/Customers/AddCustomer.vue";
//Edit Customer
import EditCustomer from "../views/children/Customers/EditCustomer.vue";
//Show Customer
import ShowCustomer from "../views/children/Customers/ShowSingle.vue";
//Add Amount
import AddAmount from "../views/children/Customers/AddAmount.vue";
//Edit Amount
import EditAmount from "../views/children/Customers/EditAmount.vue";
// ========== Customers

// ========== Branches
//Show All
import AllBranches from "../views/children/Branches/ShowAll.vue";
//Add Branch
import AddBranch from "../views/children/Branches/AddBranch.vue";
//Edit Branch
import EditBranch from "../views/children/Branches/EditBranch.vue";
// Branch Trash
import BranchTrash from "../views/children/Branches/Trash.vue";
// ========== Branches

// ========== Trash Report
import AllTrashReports from "../views/children/trashReport/ShowAll.vue";
// ========== Trash Report

// ========== Jobs
//Show All
import AllJobs from "../views/children/Jobs/ShowAll.vue";
//Add Job
import AddJob from "../views/children/Jobs/AddJob.vue";
//Edit Job
import EditJob from "../views/children/Jobs/EditJob.vue";
// ========== Jobs

// ========== Receipt
// Home
import ReceiptsHome from "../views/children/receipts/Home.vue";
// All Receipts
import AllReceipts from "../views/children/receipts/ShowAll.vue";
// Add Receipt
import AddReceipt from "../views/children/receipts/Add.vue";
// ========== Receipt

// ========== ContactMessages
//Show All
import ContactMessages from "../views/children/ContactMessages/ShowAll.vue";
//ShowContactMessages
import ShowContactMessages from "../views/children/ContactMessages/ShowContactMessages.vue";
// ========== ContactMessages

// ========== Faqs
// Show All
import AllFaqs from "../views/children/Faqs/ShowAll.vue";
// Add Faq
import AddFaq from "../views/children/Faqs/AddFaq.vue";
// Edit Faq
import EditFaq from "../views/children/Faqs/EditFaq.vue";

// ========== Transactons
// Show All
import AllTransactions from "../views/children/Transactions/AllTransactions.vue";
// Show Transaction
import Transaction from "../views/children/Transactions/ShowTransaction.vue";
// ========== Faqs

//==========Setting=========
//Main Route
import AllSettings from "../views/children/Settings/AllSettings.vue";
//====Childern====
//About Us
import AboutUs from "../views/children/Settings/AboutUs/Show.vue";
import EditAbout from "../views/children/Settings/AboutUs/Edit.vue";
//Policy
import Policy from "../views/children/Settings/Policy/Show.vue";
import EditPolicy from "../views/children/Settings/Policy/Edit.vue";
//Terms
import Terms from "../views/children/Settings/Terms/Show.vue";
import EditTerms from "../views/children/Settings/Terms/Edit.vue";
//Contact Us
import ContactUs from "../views/children/Settings/ContactUs/Show.vue";
import EditContact from "../views/children/Settings/ContactUs/Edit.vue";

import Tables from "../views/children/Tables/Tables.vue";
import Maps from "../views/children/Maps/maps.vue";
import ProfileView from "../views/children/Profile/ProfileView.vue";

// ********** Auth
import Login from "../views/Auth/Login.vue";
import resetCode from "../views/Auth/resetCode";
// ********** Forms
import FormInputs from "../views/children/Forms/InputWrapper.vue";

// **** CRUD
import Create from "../views/children/Forms/Crud/Create.vue";
import Update from "../views/children/Forms/Crud/Update.vue";

// **** Chats
import ReadOnly_chat from "../views/children/Chats/ReadOnly_chat.vue";
import ReadWrite_chat from "../views/children/Chats/ReadWrite_chat.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";

// ============================== End Views ==============================

// Vuex store
import store from "../store/index";

let router = null;
window.rootRouter = router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    // scrollBehavior() {
    //     document.getElementById('app').scrollIntoView({ behavior: 'smooth', top: 1000 });
    // },
    routes: [
        // Main
        {
            path: "/",
            component: Home,
            children: [{
                    path: "/",
                    name: "HomePage",
                    component: HomePage,
                },
                // ========== Start Countries ==========
                {
                    path: "/countries",
                    component: CountriesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllCountries",
                            component: AllCountries,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddCountry",
                            component: AddCountry,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditCountry",
                            component: EditCountry,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "CountryTrash",
                            component: CountryTrash,
                        },
                    ],
                },
                // ========== Start Countries ==========
                // ========== Start Enterprice Category ==========
                {
                    path: "/enterpriceActions",
                    component: EnterpriceActionsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEnterpriceOnwers",
                            component: EnterpriceActions,
                        },
                        {
                            path: "trash",
                            name: "AllEnterpriceOnwers",
                            component: EnterpriceActionsTrash,
                        },
                    ],
                },
                {
                    path: "/enterpriceOwners",
                    component: EnterpriceOwners,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEnterpriceOnwers",
                            component: AllEnterpriceOnwers,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddEnterpriceOwner",
                            component: AddEnterpriceOwner,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditEnterpriceOwner",
                            component: EditEnterpriceOwner,
                            props: true,
                        },
                        // Show
                        {
                            path: "show/:id",
                            name: "ShowEnterpriceOwner",
                            component: ShowEnterpriceOwner,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "EnterpriceOwnersTrash",
                            component: EnterpriceOwnersTrash,
                        },
                    ],
                },
                // ========== Start Enterprice Category ==========

                // ========== Start Enterprices ==========
                {
                    path: "/enterprices",
                    component: Enterprices,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEnterprices",
                            component: AllEnterprices,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddEnterprice",
                            component: AddEnterprice,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditEnterprice",
                            component: EditEnterprice,
                            props: true,
                        },
                        // Show
                        {
                            path: "show/:id",
                            name: "ShowEnterprice",
                            component: ShowEnterprice,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "EnterpriceTrash",
                            component: EnterpriceTrash,
                        },
                    ],
                },
                // ========== Start Enterprices ==========

                // ========== Start Enterprice Account ==========
                {
                    path: "/enterpriceAccount",
                    component: EnterpriceAccounts,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEnterpriceAccounts",
                            component: AllEnterpriceAccounts,
                        },

                        {
                            path: "reports",
                            name: "AllEnterpriceReports",
                            component: AllEnterpriceReports,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddEnterpriceAccount",
                            component: AddEnterpriceAccount,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditEnterpriceAccount",
                            component: EditEnterpriceAccount,
                            props: true,
                        },
                        // Show
                        {
                            path: "show/:id",
                            name: "ShowEnterpriceAccount",
                            component: ShowEnterpriceAccount,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "EnterpriceAccountTrash",
                            component: EnterpriceAccountTrash,
                        },
                    ],
                },
                // ========== Start Enterprice Account ==========

                // ========== Start Enterprice Credits ==========
                {
                    path: "/enterpriceCredits",
                    component: EnterpriceCredits,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEnterpriceCredits",
                            component: AllEnterpriceCredits,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddEnterpriceCredit",
                            component: AddEnterpriceCredit,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditEnterpriceCredit",
                            component: EditEnterpriceCredit,
                            props: true,
                        },
                        // Show
                        {
                            path: "show/:id",
                            name: "ShowEnterpriceCredit",
                            component: ShowEnterpriceCredit,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "EnterpriceCreditTrash",
                            component: EnterpriceCreditTrash,
                            props: true,
                        },
                        // Not Found
                        {
                            path: "notFound",
                            name: "NotFoundEnterpriceCredit",
                            component: NotFoundEnterpriceCredit,
                        },
                        // found
                        {
                            path: "found",
                            name: "FoundEnterpriceCredit",
                            component: FoundEnterpriceCredit,
                        },
                        // Found Trash
                        {
                            path: "found/trash",
                            name: "EnterpriceCreditFoundTrash",
                            component: EnterpriceCreditFoundTrash,
                        },
                        // Not Found Trash
                        {
                            path: "notFound/trash",
                            name: "EnterpriceCreditNotFoundTrash",
                            component: EnterpriceCreditNotFoundTrash,
                        },
                    ],
                },
                // ========== Start Enterprice Credits ==========
                // ========== Start Enterprice ATM ==========
                {
                    path: "/enterpriceATM",
                    component: EnterpriceATM,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEnterpriceATM",
                            component: AllEnterpriceATM,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddEnterpriceATM",
                            component: AddEnterpriceATM,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditEnterpriceATM",
                            component: EditEnterpriceATM,
                            props: true,
                        },
                        // Show
                        {
                            path: "show/:id",
                            name: "ShowEnterpriceATM",
                            component: ShowEnterpriceATM,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "EnterpriceATMTrash",
                            component: EnterpriceATMTrash,
                            props: true,
                        },
                        // Not Found
                        {
                            path: "notFound",
                            name: "NotFoundEnterpriceATM",
                            component: NotFoundEnterpriceATM,
                        },
                        // found
                        {
                            path: "found",
                            name: "FoundEnterpriceATM",
                            component: FoundEnterpriceATM,
                        },
                        // Found Trash
                        {
                            path: "found/trash",
                            name: "EnterpriceATMFoundTrash",
                            component: EnterpriceATMFoundTrash,
                        },
                        // Not Found Trash
                        {
                            path: "notFound/trash",
                            name: "EnterpriceATMNotFoundTrash",
                            component: EnterpriceATMNotFoundTrash,
                        },
                    ],
                },
                // ========== Start Enterprice Credits ==========
                // ========== Start Debits ==========
                {
                    path: "/debits",
                    component: DebitsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllDebits",
                            component: AllDebits,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddDebit",
                            component: AddDebit,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditDebit",
                            component: EditDebit,
                            props: true,
                        },
                    ],
                },
                // ========== End Debits ==========

                // ========== Start Company Transfers ==========
                {
                    path: "/companyTransfers",
                    component: CompanyTransfersHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllCompnayTransfers",
                            component: AllCompnayTransfers,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddCompanyTransfers",
                            component: AddCompanyTransfer,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditCompanyTransfers",
                            component: EditCompanyTransfer,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "CompanyTransferTrash",
                            component: CompanyTransferTrash,
                            props: true,
                        },
                    ],
                },
                // ========== Start company cach ==========
                {
                    path: "/cach",
                    component: CompaniesCachs,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllCompaniesCachs",
                            component: AllCompaniesCachs,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddCompaniesCachs",
                            component: AddCompaniesCachs,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditCompaniesCachs",
                            component: EditCompaniesCachs,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "CompaniesCachsTrash",
                            component: CompaniesCachsTrash,
                        },
                    ],
                },
                // ========== End company cach==========
                // ========== Start Company Transfers ==========
                {
                    path: "/companyAction",
                    component: CompanyActionHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllCompnayAction",
                            component: AllCompnayAction,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddCompanyAction",
                            component: AddCompanyAction,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditCompanyAction",
                            component: EditCompanyAction,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "CompanyActionTrash",
                            component: CompanyActionTrash,
                        },
                    ],
                },
                // ========== End Company Transfers ==========

                // ========== Start Debits with_id ==========
                {
                    path: "/debits/:id",
                    component: DebitsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllDebits",
                            component: AllDebits,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddDebit",
                            component: AddDebit,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "EditDebit",
                        //   component: EditDebit,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Debits ==========

                // ========== Start Products ==========
                {
                    path: "/products",
                    component: ProductsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllProducts",
                            component: AllProducts,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddProduct",
                            component: AddProduct,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditProduct",
                            component: EditProduct,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ProductTrash",
                            component: ProductTrash,
                        },
                        // --------------- sub products ------------
                        {
                            path: "sub",
                            name: "AllProductsSub",
                            component: AllProductsSub,
                        },
                        // Add
                        {
                            path: "sub/add",
                            name: "AddProductSub",
                            component: AddProductSub,
                        },
                        // Edit
                        {
                            path: "sub/edit/:id",
                            name: "EditProductSub",
                            component: EditProductSub,
                            props: true,
                        },
                        // Trash
                        {
                            path: "sub/trash",
                            name: "ProductSubTrash",
                            component: ProductSubTrash,
                        },
                    ],
                },
                // ========== End Products ==========

                // ========== Start Privillages ==========
                {
                    path: "/privillages",
                    component: PrivillagesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllPrivillages",
                            component: AllPrivillages,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddPrivillage",
                            component: AddPrivillage,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditPrivillage",
                            component: EditPrivillage,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "PrivillageTrash",
                            component: PrivillageTrash,
                        },
                    ],
                },
                // ========== End Privillages ==========

                // ************************ Start Public_Services ************************
                // ========== Start Deposits_Ultimate ==========
                {
                    path: "/deposits_ultimate",
                    component: DepositsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllDeposits",
                            component: AllDeposits,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddDeposit",
                            component: AddDeposit,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditDeposit",
                            component: EditDeposit,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "DepositTrash",
                            component: DepositTrash,
                        },
                        // Trash report
                        {
                            path: "trash_report",
                            name: "DepositTrashReport",
                            component: DepositTrashReport,
                        },
                    ],
                },
                // ========== End Deposits_Ultimate ==========

                // ========== Start Deposits With_id ==========
                {
                    path: "/deposits/:id",
                    component: DepositsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllDeposits",
                            component: AllDeposits,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddDeposit",
                            component: AddDeposit,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "EditDeposit",
                        //   component: EditDeposit,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Deposits With_id ==========

                // ========== Start Withdraws_Ultimate  ==========
                {
                    path: "/withdraws_ultimate",
                    component: WithdrawsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllWithdraws",
                            component: AllWithdraws,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddWithdraw",
                            component: AddWithdraw,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditWithdraw",
                            component: EditWithdraw,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "WithdrawTrash",
                            component: WithdrawTrash,
                        },
                        // Trash report
                        {
                            path: "trash_report",
                            name: "WithdrawTrashReport",
                            component: WithdrawTrashReport,
                        },
                    ],
                },
                // ========== End Withdraws_Ultimate  ==========

                // ========== Start Withdrws With_id ==========
                {
                    path: "/withdraws/:id",
                    component: WithdrawsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllWithdraws",
                            component: AllWithdraws,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddWithdraw",
                            component: AddWithdraw,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "EditDeposit",
                        //   component: EditDeposit,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Withdraws With_id ==========
                // ************************ End Public_Services ************************
                // ========== Start Bank_operations ==========
                {
                    path: "/bank_operations",
                    component: Bank_operationsHome,
                    children: [
                        // Show All
                        {
                            path: "deposits",
                            name: "AllBank_operations",
                            component: AllBank_operations_deposits,
                        },
                        {
                            path: "withdraws",
                            name: "AllBank_operations",
                            component: AllBank_operations_withdraws,
                        },
                        // Add withdraw
                        {
                            path: "add_withdraw",
                            name: "AddBank_operation_withdraw",
                            component: AddBank_operation_withdraw,
                        },
                        // Add Deposit
                        {
                            path: "add_deposit",
                            name: "AddBank_operation_deposit",
                            component: AddBank_operation_deposit,
                        },
                        // Edit
                        {
                            path: "edit_deposit/:id",
                            name: "EditBank_operation",
                            component: EditBank_operation_deposit,
                            props: true,
                        },
                        {
                            path: "edit_withdraw/:id",
                            name: "EditBank_operation",
                            component: EditBank_operation_withdraw,
                            props: true,
                        },
                        // Trash
                        {
                            path: "deposits/trash",
                            name: "BankOperationDepositTrash",
                            component: BankOperationDepositTrash,
                        },
                        {
                            path: "withdraws/trash",
                            name: "BankOperationWithdrawTrash",
                            component: BankOperationWithdrawTrash,
                        },
                    ],
                },
                // ========== End Bank_operations ==========

                // ========== Start Bank_deposits With_id ==========
                {
                    path: "/bank_deposits/:id",
                    component: Bank_depositsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBank_deposits",
                            component: AllBank_deposits,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBank_deposit",
                            component: AddBank_deposit,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "EditDeposit",
                        //   component: EditDeposit,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Bank_deposits With_id ==========

                // ========== Start Bank_withdraws With_id ==========
                {
                    path: "/bank_withdraws/:id",
                    component: Bank_withdrawsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBank_withdraws",
                            component: AllBank_withdraws,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBank_withdraw",
                            component: AddBank_withdraw,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "EditDeposit",
                        //   component: EditDeposit,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Bank_withdraws With_id ==========

                // ========== Start Bank_transfares_ultimate ==========
                {
                    path: "/bank_transfares_ultimate",
                    component: Bank_transfaresHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBank_transfares",
                            component: AllBank_transfares,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBank_transfare",
                            component: AddBank_transfare,
                            props: true,
                        },
                        {
                            path: "trash",
                            name: "AddBank_transfare_trash",
                            component: BankTransfareTrash,
                            props: true,
                        },
                    ],
                },
                // ========== End Bank_transfares_ultimate ==========

                // ========== Start Bank_transfares With_id ==========
                {
                    path: "/bank_transfares/:id",
                    component: Bank_transfaresHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBank_transfares",
                            component: AllBank_transfares,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBank_transfare",
                            component: AddBank_transfare,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "EditClient_account",
                        //   component: EditClient_account,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Bank_transfares With_id ==========

                // ========== Start Banks ==========
                {
                    path: "/banksDubai",
                    component: BanksDubaiHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBanksDubai",
                            component: AllBanksDubai,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBankDubai",
                            component: AddBankDubai,
                            props: true,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditBankDubai",
                            component: EditBankDubai,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "BanksDubaiTrash",
                            component: BanksDubaiTrash,
                        },
                        // report
                        {
                            path: "reports",
                            name: "BanksDubaiReports",
                            component: BanksDubaiReports,
                        },
                        // Add
                        {
                            path: "account/:id",
                            name: "BankDubaiAccount",
                            component: BankDubaiAccount,
                            props: true,
                        },
                        {
                            path: "accountTrash",
                            name: "BankDubaiAccountTrach",
                            component: BankDubaiAccountTrach,
                            props: true,
                        },
                    ],
                },
                {
                    path: "/banksDubai/amount",
                    component: BanksDubaiHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBanksDubaiAmount",
                            component: AllBanksDubaiAmount,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBankDubaiAmount",
                            component: AddBankDubaiAmount,
                            props: true,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditBankDubaiAmount",
                            component: EditBankDubaiAmount,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "BanksDubaiAmountTrash",
                            component: BanksDubaiAmountTrash,
                        },
                    ],
                },
                {
                    path: "/banksDubai/withdraw",
                    component: BanksDubaiHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBanksDubaiWithdraw",
                            component: AllBanksDubaiWithdraw,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBankDubaiAmount",
                            component: AddBankDubaiWithdraw,
                            props: true,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditBankDubaiAmount",
                            component: EditBankDubaiWithdraw,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "BanksDubaiWithdrawTrash",
                            component: BanksDubaiWithdrawTrash,
                        },
                    ],
                },
                {
                    path: "/banks",
                    component: BanksHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllBanks",
                            component: AllBanks,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddBank",
                            component: AddBank,
                            props: true,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditBank",
                            component: EditBank,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "BanksTrash",
                            component: BanksTrash,
                        },
                    ],
                },
                // ========== End Banks ==========

                // ========== Start Export_Banks ==========
                {
                    path: "/export_banks",
                    component: ExportBanksHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllExportBanks",
                            component: AllExportBanks,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddExportBank",
                            component: AddExportBank,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditExportBank",
                            component: EditExportBank,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ExportBankTrash",
                            component: ExportBankTrash,
                        },
                    ],
                },
                // ========== End Export_Banks ==========

                // ========== Start Export_Banks ==========
                {
                    path: "/export_refrences",
                    component: ExportRefrencesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllExportRefrences",
                            component: AllExportRefrences,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddExportRefrence",
                            component: AddExportRefrence,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditExportRefrence",
                            component: EditExportRefrence,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ExportRefrenceTrash",
                            component: ExportRefrenceTrash,
                        },
                    ],
                },
                // ========== End Export_Banks ==========

                // ========== Start Export_App ==========
                {
                    path: "/export_app",
                    component: ExportAppHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllExportApp",
                            component: AllExportApp,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddExportApp",
                            component: AddExportApp,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditExportApp",
                            component: EditExportApp,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ExportAppTrash",
                            component: ExportAppTrash,
                        },
                        {
                            path: "export_count",
                            name: "ExportCount",
                            component: ExportCount,
                        },
                    ],
                },
                // ========== End Export_Banks ==========

                // ========== Start ExportClient ==========
                {
                    path: "/export_client",
                    component: ExportClientHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllExportClient",
                            component: AllExportClient,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddExportClient",
                            component: AddExportClient,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditExportClient",
                            component: EditExportClient,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ExportClientTrash",
                            component: ExportClientTrash,
                        },
                    ],
                },
                // ========== End ExportClient ==========

                // ========== Start Ports And Import Files ==========
                //*****Import Companies *****/
                {
                    path: "/import-companies",
                    component: ImportCompaniesHome,
                    children: [
                        // Add
                        {
                            path: "",
                            name: "AddImportCompany",
                            component: AddImportCompany,
                        },
                        // Show All
                        {
                            path: "all",
                            name: "AllImportCompanies",
                            component: AllImportCompanies,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditImportCompany",
                            component: EditImportCompany,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ImportCompaniesTrash",
                            component: ImportCompaniesTrash,
                        },
                    ],
                },
                //*****Import Companies *****/

                //***** Ports *****/
                {
                    path: "/ports",
                    component: PortsHome,
                    children: [
                        // Add
                        {
                            path: "",
                            name: "AddPort",
                            component: AddPort,
                        },
                        // Show All
                        {
                            path: "all",
                            name: "AllPorts",
                            component: AllPorts,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditPort",
                            component: EditPort,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "PortTrash",
                            component: PortTrash,
                        },
                    ],
                },
                //***** Ports *****/

                //*****Import Files *****/
                {
                    path: "/importFiles",
                    component: ImportFilesHome,
                    children: [
                        // Add
                        {
                            path: "",
                            name: "AddImportFile",
                            component: AddImportFile,
                        },
                        // Show All
                        {
                            path: "all",
                            name: "AllImportFiles",
                            component: AllImportFiles,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditImportFile",
                            component: EditImportFile,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ImportFilesTrash",
                            component: ImportFilesTrash,
                        },
                        // sinlge
                        {
                            path: "single/:id",
                            name: "ImportFilesSingle",
                            component: ImportFilesSingle,
                        }
                    ],
                },
                // ========== End Ports And Import Files ==========

                // ========== Start Clients ==========
                {
                    path: "/clients",
                    component: ClientsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllClients",
                            component: AllClients,
                        },
                        {
                            path: "clients-reports",
                            name: "ClientsReport",
                            component: ClientsReport,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddClient",
                            component: AddClient,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditClient",
                            component: EditClient,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ClientTrash",
                            component: ClientTrash,
                        },
                        // ===== Show
                        {
                            path: "show/:id",
                            component: ShowClientHome,
                            props: true,
                            children: [{
                                    path: "",
                                    component: ShowClientDetails,
                                    props: true,
                                },
                                {
                                    path: "info",
                                    name: "ShowClientInfo",
                                    component: ShowClientInfo,
                                    props: true,
                                },
                            ],
                        },
                    ],
                },
                // ========== End Clients ==========

                // ========== Start Service_operations ==========
                {
                    path: "/service_operations",
                    component: Service_operationsHome,
                    children: [
                        // Show All
                        {
                            path: "deposit",
                            name: "AllService_operations",
                            component: AllService_operations_deposit,
                        },
                        {
                            path: "withdraw",
                            name: "AllService_operations",
                            component: AllService_operations_withdraw,
                        },
                        {
                            path: "reports",
                            name: "AllService_operationsReports",
                            component: AllService_operations_reports,
                        },
                        // Add Eithdraw
                        {
                            path: "add_withdraw",
                            name: "AddService_operation_withdraw",
                            component: AddService_operation_withdraw,
                        },
                        // Add Deposit
                        {
                            path: "add_deposit",
                            name: "AddService_operation_deposit",
                            component: AddService_operation_deposit,
                        },
                        // Edit
                        {
                            path: "edit/:id/:type",
                            name: "EditService_operation",
                            component: EditService_operation,
                            props: true,
                        },
                        // Trash
                        {
                            path: "deposit/trash",
                            name: "ServiceOperationsDepositTrash",
                            component: ServiceOperationsDepositTrash,
                        },
                        {
                            path: "withdraw/trash",
                            name: "ServiceOperationsWithdrawTrash",
                            component: ServiceOperationsWithdrawTrash,
                        },
                    ],
                },

                // ========== End Service_operations ==========
                {
                    path: "/service_operations/:id",
                    component: Service_operationsHome,
                    children: [
                        // Show All
                        {
                            path: "deposit",
                            name: "AllService_operations",
                            component: AllService_operations_deposit,
                        },
                        {
                            path: "withdraw",
                            name: "AllService_operations",
                            component: AllService_operations_withdraw,
                        },
                        // Add Eithdraw
                        {
                            path: "add_withdraw",
                            name: "AddService_operation_withdraw",
                            component: AddService_operation_withdraw,
                        },
                        // Add Deposit
                        {
                            path: "add_deposit",
                            name: "AddService_operation_deposit",
                            component: AddService_operation_deposit,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditService_operation",
                            component: EditService_operation,
                            props: true,
                        },
                    ],
                },
                // ========== Start Currency ==========
                {
                    path: "/currency",
                    component: CurrencyHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllCurrency",
                            component: AllCurrency,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddCurrency",
                            component: AddCurrency,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditCurrency",
                            component: EditCurrency,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "CurrencyTrash",
                            component: CurrencyTrash,
                        },
                        // *********** Buy Currency
                        // Show All
                        {
                            path: "/currency/buy",
                            name: "AllBuyCurrencies",
                            component: AllBuyCurrencies,
                        },
                        //  Buy Currency
                        {
                            path: "/currency/buy-currency",
                            name: "BuyCurrency",
                            component: BuyCurrency,
                        },
                        //  Edit Buy Currency
                        {
                            path: "/currency/edit-buy-currency/:id",
                            name: "EditBuyOperation",
                            component: EditBuyOperation,
                            props: true,
                        },
                        //  Buy Currency Trash
                        {
                            path: "by-currency-trash",
                            name: "BuyCurrencyTrash",
                            component: BuyCurrencyTrash,
                        },
                        // *********** Sale Currency
                        // Show All
                        {
                            path: "/currency/sale",
                            name: "AllSaleCurrencies",
                            component: AllSaleCurrencies,
                        },
                        //  Sale Currency
                        {
                            path: "/currency/sale-currency",
                            name: "SaleCurrency",
                            component: SaleCurrency,
                        },
                        //  Edit Sale Currency
                        {
                            path: "/currency/edit-sale-currency/:id",
                            name: "EditSaleOperation",
                            component: EditSaleOperation,
                            props: true,
                        },
                        //  Sale Currency Trash
                        {
                            path: "sale-currency-trash",
                            name: "SaleCurrencyTrash",
                            component: SaleCurrencyTrash,
                        },
                        // *********** Transfer Currency
                        // Show All
                        {
                            path: "/currency/transfer",
                            name: "AllTransferCurrencies",
                            component: AllTransferCurrencies,
                        },
                        //  Transfer Currency
                        {
                            path: "transfer-currency",
                            name: "TransferCurrency",
                            component: TransferCurrency,
                        },
                        //  Edit Transfer Currency
                        {
                            path: "/currency/edit-transfer-currency/:id",
                            name: "EditTransferOperation",
                            component: EditTransferOperation,
                            props: true,
                        },
                        //  Transfer Currency Trash
                        {
                            path: "/currency/transfer-currency-trash",
                            name: "TransferCurrencyTrash",
                            component: TransferCurrencyTrash,
                        },
                    ],
                },
                // ========== End Currency ==========

                // ========== Start Employees ==========
                {
                    path: "/employees",
                    component: EmployeesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllEmployees",
                            component: AllEmployees,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddEmployee",
                            component: AddEmployee,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditEmployee",
                            component: EditEmployee,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "EmployeeTrash",
                            component: EmployeeTrash,
                        },
                    ],
                },
                // ========== End Employees ==========
                // ========== Start Bills ==========
                {
                    path: "/bills-export",
                    component: BillsHome,
                    children: [
                        // // Show All
                        {
                            path: "",
                            name: "AllBills",
                            component: AllBills,
                        },
                        // Add
                        {
                            path: "add/:id",
                            name: "AddBills",
                            component: AddBills,
                        },
                    ],
                },
                // ========== End Bills ==========
                // ========== Start client_accounts ==========
                {
                    path: "/clients-amount",
                    name: "AllClient_amount",
                    component: AllClient_amount,
                    props: true,
                },
                {
                    path: "/client_accounts",
                    component: Client_accountsHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllClient_accounts",
                            component: AllClient_accounts,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddClient_account",
                            component: AddClient_account,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "Client_accountsTrash",
                            component: Client_accountsTrash,
                        },
                    ],
                },
                // ========== End client_accounts ==========

                // ========== Start Cliect Commission ==========
                {
                    path: "/clientCommission/:id",
                    component: ClientCommissionHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllClientCommission",
                            component: AllClientCommission,
                            props: true,
                        },
                    ],
                },
                // ========== End Cliect Commission ==========

                // ========== Start Expenses ==========
                {
                    path: "/expenses",
                    component: ExpensesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllExpenses",
                            component: AllExpenses,
                        },
                        // Add
                        {
                            path: "add",
                            name: "addExpenses",
                            component: addExpenses,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "editExpenses",
                            component: editExpenses,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "ExpensesTrash",
                            component: ExpensesTrash,
                        },
                    ],
                },
                // ========== End Expenses ==========

                // ========== Start Deliverables ==========
                {
                    path: "/deliverables",
                    component: DeliverablesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllDeliverables",
                            component: AllDeliverables,
                        },
                        // Add
                        {
                            path: "add",
                            name: "addDeliverables",
                            component: addDeliverables,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "editDeliverables",
                            component: editDeliverables,
                            props: true,
                        },
                    ],
                },
                // ========== End Deliverables ==========

                // ========== Start Deliverables with_id ==========
                {
                    path: "/deliverables/:id",
                    component: DeliverablesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllDeliverables",
                            component: AllDeliverables,
                            props: true,
                        },
                        // Add
                        {
                            path: "add",
                            name: "addDeliverables",
                            component: addDeliverables,
                            props: true,
                        },
                        // Edit
                        // {
                        //   path: "edit/:id",
                        //   name: "editDeliverables",
                        //   component: editDeliverables,
                        //   props: true,
                        // },
                    ],
                },
                // ========== End Deliverables with_id ==========

                // ========== Start Locks ==========
                {
                    path: "/locks",
                    component: LocksHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllLocks",
                            component: AllLocks,
                        },
                        {
                            path: "lockAmount/:id",
                            name: "LockAmount",
                            component: LockAmount,
                        },
                        {
                            path: "delete",
                            name: "DeletLock",
                            component: DeleteLocks,
                        },
                        // Add
                        {
                            path: "add",
                            name: "addLocks",
                            component: addLocks,
                        },
                        {
                            path: "edit/:id",
                            name: "editLock",
                            component: editLock,
                        },
                        // Edit
                        {
                            path: "add_lock_currency",
                            name: "addLockCurrency",
                            component: addLockCurrency,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "LocksTrash",
                            component: LocksTrash,
                        },
                        // Lock Currencies
                        {
                            path: "lock-currencies/:id",
                            name: "LockCurrencies",
                            component: LockCurrencies,
                            props: true,
                        },
                        // Lock Balances
                        {
                            path: "lock-balances/:id",
                            name: "LockBalances",
                            component: LockBalances,
                            props: true,
                        },
                        // Lock Movement
                        {
                            path: "lock-movement",
                            name: "LockMovement",
                            component: LockMovement,
                            props: true,
                        },
                        // Add Lock Movement
                        // All Transfer balances
                        {
                            path: "transfer-balances",
                            name: "AllTransferBalances",
                            component: AllTransferBalances,
                            props: true,
                        },
                        // Add Transfer balances
                        {
                            path: "transfer-balances/add",
                            name: "AddTransferBalances",
                            component: AddTransferBalances,
                            props: true,
                        },
                        // Edit Transfer balances
                        {
                            path: "transfer-balances/edit/:id",
                            name: "EditTransferBalances",
                            component: EditTransferBalances,
                            props: true,
                        },
                        // Transfer balances trash
                        {
                            path: "transfer-balances/trash",
                            name: "TransferBalancesTrash",
                            component: TransferBalancesTrash,
                        },
                    ],
                },
                // ========== End Locks ==========

                // ========== Start Receipts ==========
                {
                    path: "/receipts",
                    component: ReceiptsHome,
                    children: [
                        // // Show All
                        {
                            path: "",
                            name: "AllReceipts",
                            component: AllReceipts,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddReceipt",
                            component: AddReceipt,
                        },
                    ],
                },
                // ========== End Receipts ==========

                // ========== Start Inbox ==========
                {
                    path: "/inbox",
                    component: InboxHome,
                    children: [
                        // Inbox Balances
                        {
                            path: "inbox-balances",
                            name: "InboxBalances",
                            component: InboxBalances,
                            props: true,
                        },
                        // Inbox Movement
                        {
                            path: "inbox-movement",
                            name: "InboxMovement",
                            component: InboxMovement,
                            props: true,
                        },
                        // All Transfer balances
                        {
                            path: "transfer-balances",
                            name: "AllTransferBalances_inbox",
                            component: AllTransferBalances_inbox,
                            props: true,
                        },
                        // Add Transfer balances
                        {
                            path: "transfer-balances/add",
                            name: "AddTransferBalances_inbox",
                            component: AddTransferBalances_inbox,
                            props: true,
                        },
                        // Edit Transfer balances
                        {
                            path: "transfer-balances/edit/:id",
                            name: "EditTransferBalances_inbox",
                            component: EditTransferBalances_inbox,
                            props: true,
                        },
                    ],
                },
                // ========== End Inbox ==========

                // ========== Start Categories ==========
                {
                    path: "/categories",
                    component: CategoriesHome,
                    children: [
                        // Show All
                        {
                            path: "",
                            name: "AllCategories",
                            component: AllCategories,
                        },
                        // Add
                        {
                            path: "add",
                            name: "AddCategories",
                            component: AddCategories,
                        },
                        // Edit
                        {
                            path: "edit/:id",
                            name: "EditCategories",
                            component: EditCategories,
                            props: true,
                        },
                        // Trash
                        {
                            path: "trash",
                            name: "CategoriesTrash",
                            component: CategoriesTrash,
                            props: true,
                        },
                    ],
                },
                // ========== End Categories ==========

                // =============== Safe Transactions
                // Show All
                {
                    path: "/allSafeTransactions",
                    name: "AllSafeTransactions",
                    component: AllSafeTransactions,
                },
                // Add
                {
                    path: "/addSafeTransactions",
                    name: "AddSafeTransactions",
                    component: AddSafeTransactions,
                },
                // Edit
                {
                    path: "/editaSfeTransactions/:id",
                    name: "EditSafeTransactions",
                    component: EditSafeTransactions,
                    props: true,
                },

                // =============== Comunities
                // Show All
                {
                    path: "/allComunities",
                    name: "AllComunities",
                    component: AllComunities,
                },
                // Add Comunity
                {
                    path: "/addComunity",
                    name: "AddComunity",
                    component: AddComunity,
                },
                // Edit Comunity
                {
                    path: "/editComunity/:id",
                    name: "EditComunity",
                    component: EditComunity,
                    props: true,
                },

                // =============== Companies
                // Show All
                {
                    path: "/AllCompanies",
                    name: "AllCompanies",
                    component: AllCompanies,
                },
                {
                    path: "/AllCompanies/companies_waiting",
                    name: "AllCompanies",
                    component: CompaniesWaiting,
                },
                // Add Company
                {
                    path: "/addCompany",
                    name: "AddCompany",
                    component: AddCompany,
                },
                // Edit Company
                {
                    path: "/editCompany/:id",
                    name: "EditCompany",
                    component: EditCompany,
                    props: true,
                },
                // Show Company
                {
                    path: "/company/:id",
                    name: "ShowCompany",
                    component: ShowCompany,
                    props: true,
                },
                // Trash
                {
                    path: "companies/trash",
                    name: "CompanyTrash",
                    component: CompanyTrash,
                },
                {
                    path: "waiting-companies/trash",
                    name: "WaitingCompanyTrash",
                    component: WaitingCompanyTrash,
                },

                // =============== Customers
                // Show All
                {
                    path: "/customers",
                    name: "Customers",
                    component: Customers,
                },
                // Add Customer
                {
                    path: "/addCustomer",
                    name: "AddCustomer",
                    component: AddCustomer,
                },
                // Edit Customer
                {
                    path: "/editCustomer/:id",
                    name: "EditCustomer",
                    component: EditCustomer,
                    props: true,
                },
                // Edit Customer
                {
                    path: "/customer/:id",
                    name: "ShowCustomer",
                    component: ShowCustomer,
                    props: true,
                },
                // Add Amount
                {
                    path: "/addAmount",
                    name: "AddAmount",
                    component: AddAmount,
                },
                // Edit Customer
                {
                    path: "/editAmount/:id",
                    name: "EditAmount",
                    component: EditAmount,
                    props: true,
                },

                // =============== Branches
                // Show All
                {
                    path: "/allBranches",
                    name: "AllBranches",
                    component: AllBranches,
                },
                // Add Branch
                {
                    path: "/addBranch",
                    name: "AddBranch",
                    component: AddBranch,
                },
                // Edit Branch
                {
                    path: "/editBranch/:id",
                    name: "EditBranch",
                    component: EditBranch,
                    props: true,
                },
                //  Branch Trash
                {
                    path: "/branches/trash",
                    name: "BranchTrash",
                    component: BranchTrash,
                },

                // =============== Jobs
                // Show All
                {
                    path: "/allJobs",
                    name: "AllJobs",
                    component: AllJobs,
                },
                // Add Job
                {
                    path: "/addJob",
                    name: "AddJob",
                    component: AddJob,
                },
                // Edit Job
                {
                    path: "/editJob/:id",
                    name: "EditJob",
                    component: EditJob,
                    props: true,
                },

                // =============== Contact Messages
                // Show All
                {
                    path: "/contactMessages",
                    name: "ContactMessages",
                    component: ContactMessages,
                },
                // Show Contact Messages
                {
                    path: "/showContactMessage/:id",
                    name: "ShowContactMessages",
                    component: ShowContactMessages,
                    props: true,
                },

                // =============== Privillages
                // Show All
                {
                    path: "/allPrivillages",
                    name: "AllPrivillages",
                    component: AllPrivillages,
                },
                // Add Privillage
                {
                    path: "/addPrivillage",
                    name: "AddPrivillage",
                    component: AddPrivillage,
                },
                // Edit Privillage
                {
                    path: "/editPrivillage/:id",
                    name: "EditPrivillage",
                    component: EditPrivillage,
                    props: true,
                },
                // =============== Privillages

                // =============== Tables
                {
                    path: "/tables",
                    name: "Tables",
                    component: Tables,
                },

                // =============== Forms
                {
                    path: "/form-inputs",
                    component: FormInputs,
                    name: "FormInputs",
                },
                {
                    path: "/create",
                    component: Create,
                    name: "Create",
                },
                {
                    path: "/update",
                    component: Update,
                    name: "Update",
                },

                // =============== Maps
                {
                    path: "/maps",
                    component: Maps,
                    name: "Maps",
                },

                // =============== Chats
                {
                    path: "/readOnly_chat/:id",
                    component: ReadOnly_chat,
                    name: "ReadOnly_chat",
                    props: true,
                },
                {
                    path: "/readWrite_chat/:id",
                    component: ReadWrite_chat,
                    name: "ReadWrite_chat",
                    props: true,
                },

                // =============== Profile
                {
                    path: "/profile-view",
                    component: ProfileView,
                    name: "ProfileView",
                },

                // ========== Settings ========
                {
                    path: "/allSettings",
                    name: "AllSettings",
                    component: AllSettings,
                    children: [
                        //About
                        {
                            path: "/aboutUs",
                            name: "AboutUs",
                            component: AboutUs,
                        },
                        {
                            path: "/editAbout",
                            name: "EditAbout",
                            component: EditAbout,
                        },
                        //Terms
                        {
                            path: "/terms",
                            name: "Terms",
                            component: Terms,
                        },
                        {
                            path: "/editTerms",
                            name: "EditTerms",
                            component: EditTerms,
                        },
                        //Policy
                        {
                            path: "/policy",
                            name: "Policy",
                            component: Policy,
                        },
                        {
                            path: "/editPolicy",
                            name: "EditPolicy",
                            component: EditPolicy,
                        },
                        //Contact
                        {
                            path: "/contactUs",
                            name: "ContactUs",
                            component: ContactUs,
                        },
                        {
                            path: "/editContact",
                            name: "EditContact",
                            component: EditContact,
                        },
                    ],
                },
                // ========== Settings ========

                // =============== Faqs
                // Show All
                {
                    path: "/allFaqs",
                    name: "AllFaqs",
                    component: AllFaqs,
                },
                // Add Faq
                {
                    path: "/addFaq",
                    name: "AddFaq",
                    component: AddFaq,
                },
                // Edit Faq
                {
                    path: "/editFaq/:id",
                    name: "EditFaq",
                    component: EditFaq,
                    props: true,
                },
                // =============== Faqs

                // =============== Transaction
                // Show All
                {
                    path: "/allTransactions",
                    name: "AllTransactions",
                    component: AllTransactions,
                },
                // Show Transaction
                {
                    path: "/transaction/:id",
                    name: "Transaction",
                    component: Transaction,
                    props: true,
                },
                // =============== Transaction

                // =============== Trash Report
                {
                    path: "/trash-report",
                    name: "AllTrashReports",
                    component: AllTrashReports,
                },
                // =============== Trash Report
            ],
        },

        // Auth
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/reset-code",
            name: "Login",
            component: resetCode,
        },
        // NotFound
        { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
    ],
});
router.beforeEach((to, _2, next) => {
    if (to.name !== "Login" && !store.getters["auth_module/token"]) {
        next({ name: "Login" });
    } else if (to.name == "Login" && store.getters["auth_module/token"]) {
        next("/");
    } else if (to.name == "Login" || to.name == "NotFound") {
        store.dispatch("sideNav_module/reseatBodyPadding");
        next();
    } else {
        next();
    }
});

Vue.use(VueRouter);
export default router;