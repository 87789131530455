<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb title="DataTables" :items="items" />
    <!-- End Breadcrumb -->

    <!-- ============= Props In v-data-table -->
    <!-- 
    dense                                     /* 0 padding rows */
    :items-per-page="5"                       /* if ubsent view all */
    :search="search"                          /* Serch value in the v-text-search */
    :custom-filter="filterSearch"             /* Apply Search Custome Search Function */
    hide-default-header                       /* Hide header */
    hide-default-footer                       /* Hide Footer */
    loading-text="Loading... Please wait"     /* Loading Text */
    :single-select="singleSelect"             /* enable or disable single select mode */
                                   /* Show checkboxes on rows */
    item-key="name"                           /* must be a key in row represent a uniqe identefire for each row */
  -->

    <v-data-table
      class=" thumb"
      :headers="headers"
      :items="rows"
      :search="search"
      :custom-filter="filterSearch"
      :loading="lodaing"
      :loading-text="$t('table.loadingData')"
      v-model="selected"
      :single-select="singleSelect"
      :items-per-page="rows.length"
      item-key="id"
      hide-default-footer
    >
      <!-- ====== You Can use any slots you want ====== -->
      <!-- Select header field -->
      <template v-slot:[`header.name`]="{ header }">
        {{ header.text + " ..." }}
      </template>
<template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>
      <!-- Select row field -->
      <template v-slot:[`item.calories`]="{ item }">
        <v-chip :color="getColor(item.calories)" dark>
          {{ item.calories }}
        </v-chip>
      </template>

      <!-- Select no data State -->
      <template v-slot:no-data>
        {{ $t("table.noData") }}
      </template>

      <!-- Select actions-->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="_actions">
          <v-icon class="show" small @click="showItem(item)">
            fas fa-eye
          </v-icon>
          <v-icon class="edit" small @click="editItem(item)">
            fal fa-edit
          </v-icon>
          <v-icon class="delete" small @click="deleteItem(item)">
            fal fa-trash
          </v-icon>
        </div>
      </template>

      <!-- Select Image-->
      <template v-slot:[`item.avatar`]="{ item }">
        <img @click="show_model_1" class="image" :src="item.avatar" />
      </template>
      <!-- ====== You Can use any slots you want ====== -->

      <!-- ======================== Start Top Section ======================== -->
      <template v-slot:top>
        <!-- Delete dialog -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5 justify-center">{{
              $t("table.deletedialog.areYouSure")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1B5E20" @click="deleteItemConfirm">{{
                $t("table.deletedialog.ok")
              }} <span class="btn_loader_model" v-if="loadingBtnDialog"></span></v-btn>
              <v-btn color="#F44336" @click="dialogDelete = false">{{
                $t("table.deletedialog.cancel")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add button -->
        <v-btn @click="addItem" class="m-2" small fab dark color="indigo">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>

        <!-- Row -->
        <v-row>
          <v-col cols="12" sm="6">
            <!-- Select All -->
            <v-switch
              v-model="singleSelect"
              :label="$t('table.singleSelect')"
              class="px-3 toggleCheckOption"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6">
            <!-- Search -->
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
              class="mx-4"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <!-- ======================== End Top Section ======================== -->

      <!-- ======================== Start Body Section ======================== -->
      <template v-slot:[`body.append`]>
        <tr>
          <td></td>
          <td></td>
          <td>
            <v-text-field
              v-model="calories"
              type="text"
              :label="$t('table.lessThan')"
            ></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template>
      <!-- ======================== End Body Section ======================== -->
    </v-data-table>

    <!-- Model_1 -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Main_Table",
          disabled: true,
          href: "",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== elected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
    };
  },

  computed: {
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          {
            text: "الصورة", // the name to display
            align: "center", // [center, start]
            value: "avatar", // must be equal to the name in rows object
          },
          {
            text: "السعرات",
            value: "calories",
            align: "center",
            filter: (value) => {
              // filter the columns crosponding to a function
              if (!this.calories) return true;

              return value < parseInt(this.calories);
            },
          },
          {
            text: "الدهون (جم)",
            value: "fat",
            align: "center",
          },
          { text: "الكربوهيدرات (جم)", value: "carbs", align: "center" },
          { text: "البروتين (جم)", value: "protein", align: "center" },
          {
            text: "الحديد (%)",
            value: "iron",
            align: "center",
            filterable: true, // is to be searched or not
            sortable: true, // be able to sort or not || default true
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      } else {
        return [
          {
            text: "Avatar", // the name to display
            align: "center", // [center, start]
            value: "avatar", // must be equal to the name in rows object
          },
          {
            text: "Calories",
            value: "calories",
            align: "center",
            filter: (value) => {
              // filter the columns crosponding to a function
              if (!this.calories) return true;

              return value < parseInt(this.calories);
            },
          },
          { text: "Fat (g)", value: "fat", align: "center" },
          { text: "Carbs (g)", value: "protein", align: "center" },
          { text: "Protein (g)", value: "protein" },
          {
            text: "Iron (%)",
            value: "iron",
            align: "center",
            filterable: true, // is to be searched or not
            sortable: true, // be able to sort or not || default true
          },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    // ===== Search Method =====
    // Here filter to only uppercase || it exist by default
    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // Logic on specific column
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },

    setRows() {
      this.lodaing = true;

      // let pageIndex;
      // if (pageNumber) {
      //   pageIndex = pageNumber;
      // } else {
      //   pageIndex = 1;
      // }

      setTimeout(() => {
        this.rows = [
          {
            id: 1,
            avatar:
              "https://ui.alalmiyalhura.com/new_dashboard/img/joker.830f1452.jpg",
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: "1%",
          },
          {
            id: 2,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: "1%",
          },
          {
            id: 3,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: "7%",
          },
          {
            id: 4,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: "8%",
          },
          {
            id: 5,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: "16%",
          },
          {
            id: 6,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: "0%",
          },
          {
            id: 7,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: "2%",
          },
          {
            id: 8,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: "45%",
          },
          {
            id: 9,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: "22%",
          },
          {
            id: 10,
            avatar:
              "https://inflco.o.aait-d.com/storage/images/user/D3lukOvbubcPoVvEoFKLuYTYMKkaE46kPEFSvdQH.png",
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: "6%",
          },
        ];

        this.lodaing = false;
      }, 2000);
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== CRUD ====================
    addItem() {
      console.log("Add Item");
    },
    showItem(item) {
      console.log("Show Item");
      console.log(item);
    },
    editItem(item) {
      console.log("Edite Item");
      console.log(item);
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.dialogDelete = false;
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
  },
};
</script>

<style lang="scss" scoped></style>
