<template>
  <div class="uplodeFiles">
    <div class="input_wrapper top_label">
      <label for="text_input_1" class="form-label">المرفقات</label>
      <input
        v-model="files_to_show"
        readonly
        disabled
        type="text"
        class="form-control show"
      />
      <input
        ref="uplode_file"
        class="file_input"
        type="file"
        multiple
        accept=".txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,video/*"
        name="filesToUplode"
        @change="chooseFiles"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["inputChanged"],

  props: {
    data_src: {
      default: () => [],
      required: false,
      type: Array,
    },
  },

  data() {
    return {
      filesToUplode: [],
    };
  },

  computed: {
    files() {
      return [...this.filesToUplode, ...this.data_src];
    },
    files_to_show() {
      return this.filesToUplode
        .map((file) => {
          return '"' + file.name.slice(0, 10) + '"';
        })
        .join(" - ");
    },
  },

  methods: {
    chooseFiles(e) {
      let files = Array.from(e.target.files);

      files.forEach((file, index) => {
        if (file) {
          this.filesToUplode.push({
            uniqueID: (Math.random() + 1).toString(36).substring(7),
            obj: file,
            name: file.name,
          });
        }

        e.target.value = null;
      });

      this.$emit("inputChanged", this.files);
    },

    deleteFile(file) {
      if (file.id) {
        this.$emit("fileDeleted", file.id);
      } else {
        this.filesToUplode = this.filesToUplode.filter((loob_file) => {
          return loob_file.uniqueID != file.uniqueID;
        });
        this.$emit("inputChanged", this.files);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.uplodeFiles {
  margin-bottom: 15px;

  .input_wrapper {
    position: relative;
    width: 100%;
    height: 60px;
    text-align: center;
    cursor: pointer;
    margin: 10px auto;

    .show {
    }

    .file_input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: inline-block;
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
