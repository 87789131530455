<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="wrraper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :show_print="true"
        :excelFile="true"
        :excelData="excelData"
      />
      <!-- End Breadcrumb -->
      <!-- Start Statistics Card-->
      <ul class="all-state-count">
        <li>
          <div class="state-count">
            <span> عدد الفروع :</span>
            <span>{{ table_length }}</span>
          </div>
        </li>
      </ul>
      <!-- End Statistics Card-->
      <main class="main-table table-print">
        <!-- start of  header of printSection  -->
        <PrintHead :title="'الفروع'" :number="table_length"></PrintHead>
        <!-- end of header of printSection  -->
        <v-data-table
          class="thumb"
          :headers="headers"
          :items="rows"
          :search="search"
          :custom-filter="filterSearch"
          :loading="lodaing"
          :loading-text="$t('table.loadingData')"
          v-model="selected"
          :single-select="singleSelect"
          :items-per-page="rows.length"
          item-key="id"
          hide-default-footer
        >
          <!-- ====== You Can use any slots you want ====== -->
          <!-- Select header field -->
          <template v-slot:[`header.name`]="{ header }">
            {{ header.text + " ..." }}
          </template>
          <template v-slot:[`item.index`]="{ index }">
            {{ index + 1 }}
          </template>
          <!-- Select row field -->
          <template v-slot:[`item.calories`]="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}
            </v-chip>
          </template>
          <!-- revision status-->
          <template v-slot:[`item.review_status`]="{ item }">
            <v-chip color="#d7c100" v-if="item.review_status == 0">
              منتظر
            </v-chip>
            <v-chip color="green" v-else> تأكيد </v-chip>
          </template>
          <!-- Confirm revision-->
          <template v-slot:[`item.revision_done`]="{ item }">
            <!--  -->
            <div class="_actions actions2">
              <button
                type="button"
                class="accept confirm_btn ml-0"
                @click="revisionDone(item)"
                v-if="item.review_status == 0"
              >
                <i class="far fa-check"></i>
              </button>
              <span v-else> ---- </span>
            </div>
          </template>
          <!-- Select no data State -->
          <template v-slot:no-data>
            {{ $t("table.noData") }}
          </template>

          <!-- Select actions-->
          <template v-slot:[`item.actions`]="{ item }">
            <div class="_actions">
              <v-icon class="edit" small @click="editItem(item)">
                fal fa-edit
              </v-icon>
              <v-icon class="delete" small @click="deleteItem(item)">
                fal fa-trash
              </v-icon>
            </div>
          </template>

          <!-- Select Image-->
          <template v-slot:[`item.avatar`]="{ item }">
            <img @click="show_model_1" class="image" :src="item.avatar" />
          </template>
          <!-- ====== You Can use any slots you want ====== -->

          <!-- ======================== Start Top Section ======================== -->
          <template v-slot:top>
            <!-- Delete dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">{{
                  $t("table.deletedialog.areYouSure")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="deleteItemConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogDelete = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- confirm dialog -->
            <v-dialog v-model="dialogConfirm" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">
                  هل تمت المراجعه علي العملية ؟
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1B5E20" @click="revisionConfirm"
                    >{{ $t("table.deletedialog.ok") }}
                    <span
                      class="btn_loader_model"
                      v-if="loadingBtnDialog"
                    ></span
                  ></v-btn>
                  <v-btn color="#F44336" @click="dialogConfirm = false">{{
                    $t("table.deletedialog.cancel")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <!-- ======================== End Top Section ======================== -->
        </v-data-table>
      </main>

      <!-- Model_1 -->
      <base-model
        @closeModel="model_1.show_model = false"
        :show="model_1.show_model"
      >
        <div class="image">
          <img
            v-if="model_1.model_img_src"
            :src="model_1.model_img_src"
            alt="..."
          />
        </div>
      </base-model>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // loader
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: "الفروع",
          disabled: false,
          href: "/allBranches",
        },
        {
          text: "كل الفروع",
          disabled: true,
          href: "",
        },
      ],

      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "كل الوظائف",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,
      dialogConfirm: false,
      itemtoConfirm: null,
      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== selected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
      table_length: null,
      // excel data
      excelData: {
        name: "الفروع",
        items: null,
        json_fields: {
          "اسم الفرع": "name_ar",
        },
      },
    };
  },
  components: {
    StatsCard,
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم الفرع", value: "name_ar", align: "center" },
          {
            text: "المراجعة",
            value: "review_status",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "تأكيد",
            value: "revision_done",
            align: "center",
            sortable: false,
            class: "showOnPrint",
            cellClass: "showOnPrint",
            width: 50,
          },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      }
    },
  },

  methods: {
    setRows() {
      this.lodaing = true;
      this.$axios.get("client/branch").then((res) => {
        this.rows = res.data.data;
        this.excelData.items = this.rows;
        this.lodaing = false;
      });
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== CRUD ====================
    addItem() {
      this.$router.push({ path: "/addBranch" });
    },

    editItem(item) {
      this.$router.push({ path: "/editBranch/" + item.id });
    },
    // confirm item
    revisionDone(item) {
      this.dialogConfirm = true;
      this.itemtoConfirm = item;
    },
    revisionConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "POST",
        url: `client/branch/review/${this.itemtoConfirm.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogConfirm = false;
          this.$iziToast.success({
            timeout: 2000,
            message: "تم التأكيد بنجاح",
            position: "topRight",
          });
        })
        .catch((err) => {
          this.dialogConfirm = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },

    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/branch/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.setRows();
          this.dialogDelete = false;
          this.loadingBtnDialog = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.loadingBtnDialog = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },
    // ==================== End CRUD ====================

    // ================ Set Rows
    setRows() {
      this.rows = [];
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/branch",
      })
        .then((res) => {
          this.rows = res.data.data;
          this.table_length = this.rows.length;
          this.excelData.items = this.rows;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
