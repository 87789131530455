<template>
  <div class="custom_card">
    <span class="icon"><i class="fal fa-exclamation-triangle"></i></span>
    <h2>{{ $t("NotFound.title") }}</h2>
    <p>{{ $t("NotFound.desc") }}</p>
    <router-link to="/" class="link-hover b-two-main ml-2">
      <span>{{ $t("NotFound.button") }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  methods: {
  },
};
</script>

<style lang="scss" scoped>
#pdfTest {
  background: red;
  color: #fff;
  padding: 20px;
}

.custom_card {
  .icon {
    i {
      font-size: 50px;
      margin-bottom: 10px;
    }
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
