<template>
  <div class="wrraper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <!-- Start Statistics Card -->
    <div class="row mb-5">
      <div class="col-md-3">
        <stats-card
          :icon="'fal fa-axe'"
          :title="'كل الوظائف'"
          :number="rows.length"
          :color="current_theme == 'dark' ? '#525e6e' : '#acc4d9'"
          :bgColor="'transparent'"
        ></stats-card>
      </div>
    </div>
    <!-- End Statistics Card -->

    <v-data-table
      class="thumb"
      :headers="headers"
      :items="rows"
      :search="search"
      :custom-filter="filterSearch"
      :loading="lodaing"
      :loading-text="$t('table.loadingData')"
      v-model="selected"
      :single-select="singleSelect"
      :items-per-page="rows.length"
      item-key="id"
      hide-default-footer
    >
      <!-- ====== You Can use any slots you want ====== -->
      <!-- Select header field -->
      <template v-slot:[`header.name`]="{ header }">
        {{ header.text + " ..." }}
      </template>
      <template v-slot:[`item.index`]="{ index }">
        {{ index + 1 }}
      </template>
      <!-- Select row field -->
      <template v-slot:[`item.calories`]="{ item }">
        <v-chip :color="getColor(item.calories)" dark>
          {{ item.calories }}
        </v-chip>
      </template>

      <!-- Select no data State -->
      <template v-slot:no-data>
        {{ $t("table.noData") }}
      </template>

      <!-- Select actions-->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="_actions">
          <v-icon class="edit" small @click="editItem(item)">
            fal fa-edit
          </v-icon>
          <v-icon class="delete" small @click="deleteItem(item)">
            fal fa-trash
          </v-icon>
        </div>
      </template>

      <!-- Select Image-->
      <template v-slot:[`item.avatar`]="{ item }">
        <img @click="show_model_1" class="image" :src="item.avatar" />
      </template>
      <!-- ====== You Can use any slots you want ====== -->

      <!-- ======================== Start Top Section ======================== -->
      <template v-slot:top>
        <!-- Delete dialog -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5 justify-center">{{
              $t("table.deletedialog.areYouSure")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#1B5E20" @click="deleteItemConfirm"
                >{{ $t("table.deletedialog.ok") }}
                <span class="btn_loader_model" v-if="loadingBtnDialog"></span
              ></v-btn>
              <v-btn color="#F44336" @click="dialogDelete = false">{{
                $t("table.deletedialog.cancel")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Row -->
        <v-row>
          <v-col cols="12" sm="8">
            <!-- Search -->
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
              class="mx-4"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <section class="table-buttons">
              <button
                @click="addItem"
                to="/addStore"
                class="button_add_new ml-5"
              >
                <i class="fas fa-plus"></i>&nbsp; {{ $t("addNew") }}
              </button>
              <button
                type="button"
                class="button_delete ml-5"
                v-if="selected.length != 0"
              >
                <i class="far fa-trash-alt"></i>&nbsp; حذف المحدد
              </button>
            </section>
          </v-col>
        </v-row>
      </template>
      <!-- ======================== End Top Section ======================== -->
    </v-data-table>

    <!-- Model_1 -->
    <base-model
      @closeModel="model_1.show_model = false"
      :show="model_1.show_model"
    >
      <div class="image">
        <img
          v-if="model_1.model_img_src"
          :src="model_1.model_img_src"
          alt="..."
        />
      </div>
    </base-model>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatsCard from "./../../../components/Charts/ChartComponent.vue";
export default {
  data() {
    return {
      // loadingBtnDialog
      loadingBtnDialog: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("Jobes.title"),
          disabled: false,
          href: "/allJobes",
        },
        {
          text: this.$t("showAll"),
          disabled: true,
          href: "",
        },
      ],

      statisticsItems: [
        {
          id: 1,
          icon: "fal fa-images",
          title: "كل الوظائف",
          number: "10",
        },
      ],

      // ========== Top Section
      search: "",

      // ========== dialog Status
      dialogDelete: false,
      dialogDelete_selected: false,
      itemtoDelete: null,

      // ========== Model
      model_1: {
        show_model: false,
        model_img_src: "",
      },

      // ========== Body Section
      calories: "",

      // ========== Your Data
      rows: [], // injected in created

      // ========== selected Data
      selected: [],
      singleSelect: false,

      // ========== Loding
      lodaing: false,
    };
  },
  components: {
    StatsCard,
  },
  computed: {
    current_theme() {
      return this.$store.getters["theme_module/current_theme"];
    },
    ...mapGetters({
      lang: "lang_module/lang",
    }),

    headers() {
      if (this.lang == "ar") {
        return [
          {
            text: "#",
            value: "index",
            sortable: false,
            align: "center",
            width: 10,
          },
          { text: "اسم الوظيفة", value: "name_ar", align: "center" },
          // { text: "اسم الوظيفة (انجليزي)", value: "name_en", align: "center" },
          { text: "وصف الوظيفة", value: "desc_ar", align: "center" },
          // {
          //   text: "وصف الوظيفة (انجليزي)",
          //   value: "desc_en",
          //   align: "center",
          // },
          {
            text: "التحكم",
            value: "actions",
            sortable: false,
            align: "center",
            class: "showOnPrint",
            cellClass: "showOnPrint",
          },
        ];
      } else {
        return [
          {
            text: "Avatar", // the name to display
            align: "center", // [center, start]
            value: "avatar", // must be equal to the name in rows object
          },
          { text: "Job Name", value: "name_ar", align: "center" },
          // { text: "Job Name (English)", value: "name_en", align: "center" },
          {
            text: "Job desc",
            value: "desc_ar",
            align: "center",
          },
          // {
          //   text: "Job desc (English)",
          //   value: "desc_en",
          //   align: "center",
          // },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ];
      }
    },
  },

  methods: {
    filterClick(word) {
      if (!this.lodaing) {
        if (word != "كل الإسلايدرز") {
          this.search = word;
        } else {
          this.search = "";
        }
        this.filterSearch();
      }
    },
    // ===== Search Method =====
    filterSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    // Logic on specific column
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },

    // ===== Delete
    deleteItem(item) {
      this.dialogDelete = true;
      this.itemtoDelete = item;
    },
    deleteItemConfirm() {
      this.loadingBtnDialog = true;
      this.$axios({
        method: "DELETE",
        url: `client/position/${this.itemtoDelete.id}`,
      })
        .then(() => {
          this.loadingBtnDialog = false;
          this.setRows();
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("CRUD.Delete"),
            position: "topRight",
          });
          this.$store.dispatch("getTranshState");
        })
        .catch((err) => {
          this.dialogDelete = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
        });
    },

    setRows() {
      this.rows = [];
      this.lodaing = true;
      this.$axios({
        method: "GET",
        url: "client/position",
      })
        .then((res) => {
          this.rows = res.data.data;
          this.lodaing = false;
        })
        .catch((err) => {
          console.log(err);
          this.lodaing = false;
        });
    },

    // img Model
    show_model_1(e) {
      this.model_1.model_img_src = e.target.src;
      this.model_1.show_model = true;
    },

    // ==================== CRUD ====================
    addItem() {
      this.$router.push({ path: "/addJob" });
    },
    editItem(item) {
      this.$router.push({ path: "/editJob/" + item.id });
    },
  },

  // ======= hooks
  created() {
    // Set Rows
    this.setRows();
  },
};
</script>
