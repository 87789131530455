<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->
    <div class="custom_card">
      <!-- START:: CARD TITLE -->
      <div class="card-header">
        <h4 class="card-title">{{ $t("CRUD.Update.main_title") }}</h4>
      </div>
      <!-- END:: CARD TITLE -->

      <!-- START:: CREATE FORM WRAPPER -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-12 py-0">
              <!-- START:: TITLE INPUT -->
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  id="amount_input"
                  @input="checkIfInputIsEmpty"
                  v-model.trim="updateData.amount"
                />
                <label for="name_input" class="form-label">{{
                  $t("customers.data.amount")
                }}</label>
              </div>
              <!-- END:: TITLE INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->

            <!-- START:: INPUT WRAPPER -->
            <div class="col-lg-12 py-0">
              <!-- START:: MULTIPLE SELECT INPUT -->
              <div class="input_wrapper top_label">
                <label class="form-label">{{
                  $t("customers.data.currency")
                }}</label>
                <multiselect
                  v-model="updateData.multiple_select_value"
                  :options="single_select_options"
                  label="name"
                  track-by="name"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <!-- END:: MULTIPLE SELECT INPUT -->
            </div>
            <!-- END:: INPUT WRAPPER -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <!-- START:: BUTTON -->
          <button
            class="button_style_1"
            :class="{buttonIndex: btnIsLoading}"
            :disabled="btnIsLoading"
          >
            {{ $t("Forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
          <!-- END:: BUTTON -->
        </div>
      </form>
      <!-- END:: CREATE FORM WRAPPER -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("mainHome"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("customers.title"),
          disabled: false,
          href: "/allcustomers",
        },
        {
          text: this.$t("customers.amounts"),
          disabled: true,
          href: "",
        },
        {
          text: this.$t("edit"),
          disabled: true,
          href: "",
        },
      ],

      // START:: BUTTON LOADER HANDLING DATA
      btnIsLoading: false,
      // END:: BUTTON LOADER HANDLING DATA

      // START:: SINGLE SELECT DATA
      single_select_options: [
        {id: 1, name: "طالب"},
        {id: 2, name: "ولي امر"},
        {id: 3, name: "معلم"},
        {id: 4, name: "عام"},
      ],
      // END:: SINGLE SELECT DATA

      // START:: CREATE DATA
      updateData: {
        amount: null,
        multiple_select_value: "",
      },
      // END:: CREATE DATA
    };
  },

  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.updateData.avatar = obj;
    },
    // END:: Uplode Avatar

    // START:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)
    checkIfInputIsEmpty(e) {
      let inputElement = e.currentTarget;
      if (inputElement.value.length > 0) {
        inputElement.classList.add("not_empty");
      } else {
        inputElement.classList.remove("not_empty");
      }
    },
    // END:: CHECK IF INPUT IS EMPTY (SPECIFIC TO ANIMATED PLACEHOLDER INPUTS)

    // START:: VALIDATE CREATE FORM
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.updateData.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("customers.validation.amount"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.updateData.multiple_select_value) {
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("customers.validation.currency"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("global.addSuccess"),
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        }
      }
    },
    // END:: VALIDATE CREATE FORM
  },
};
</script>
