export default {
    contracts(state) {
        return state.contracts;
    },

    transfares(state) {
        return state.transfares;
    },

    messages(state) {
        return state.messages;
    },

    requests(state) {
        return state.requests;
    },

    influencers(state) {
        return state.influencers;
    },

    investors(state) {
        return state.investors;
    },
    platformRequests(state) {
        return state.platformRequests;
    },
    notification(state) {
        return state.notification;
    },
    loading(state) {
        return state.loading;
    },
};