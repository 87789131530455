<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="create_wrapper" v-else>
      <!-- Start Breadcrumb -->
      <Breadcrumb
        :items="items"
        :search="true"
        search_route="/cach"
        search_title="اقساط الشركات"
      />
      <!-- End Breadcrumb -->

      <div class="custom_card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("edit") }}</h4>
        </div>

        <!-- ==== Start Form ==== -->
        <form @submit.prevent="validateCreateForm">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> صاحب الاستحقاق </label>
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model="data.companyName"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.amount"
                  />
                  <label for="name_input" class="form-label">{{
                    $t("forms.labels.amount")
                  }}</label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label auto_complete">
                  <label class="form-label"> العملة</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model="data.currency"
                  />
                </div>
              </div>
              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.voucher_number"
                  />
                  <label for="name_input" class="form-label">رقم الشيك</label>
                </div>
              </div>

              <div class="col-lg-6 py-0">
                <div class="input_wrapper top_label file_input">
                  <span class="file_input_label"> صورة الشيك </span>
                  <label
                    for="reseat_image"
                    class="form-label"
                    v-if="!selectedFile.name"
                  ></label>
                  <label for="reseat_image" class="form-label" v-else>
                    {{ selectedFile.name }}
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="reseat_image"
                    @change="handelSelectedFile"
                  />
                </div>
              </div>
              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label date_input">
                  <label class="form-label">تاريخ الإستحقاق</label>
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="data.date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="dateMenu = false"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">{{
                    $t("forms.labels.bank")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model="data.bank"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <label class="form-label">نوع الحساب</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model="data.accountType"
                  />
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    @input="helper_checkIfInputIsEmpty"
                    v-model.trim="data.accountNum"
                  />
                  <label for="name_input" class="form-label">رقم الحساب</label>
                </div>
              </div>

              <div class="col-lg-12 py-0">
                <div class="input_wrapper top_label">
                  <label for="textarea_1" class="form-label">{{
                    $t("forms.labels.notes")
                  }}</label>
                  <textarea
                    v-on:keydown.enter.prevent="validateCreateForm"
                    v-model="data.notes"
                    class="form-control"
                    id="textarea_1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons_wrapper">
            <button
              class="button_style_1"
              :class="{buttonIndex: btnIsLoading}"
              :disabled="btnIsLoading"
            >
              تعديل
              <span class="btn_loader" v-if="btnIsLoading"></span>
            </button>
          </div>
        </form>
        <!-- ==== End Form ==== -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      // loader
      load_companies: false,

      searchOptions: {
        search_company: "",
      },
      companies: null,
      loading: true,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: "تعديل قسط لشركة",
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // Date_menues
      dateMenu: false,
      debit_dateMenu: false,

      // START:: SELECTED FILE DATA
      selectedFile: {
        file: null,
        name: null,
        path: null,
      },

      // ========== create_data
      data: {
        companyName: null,
        amount: null,
        currency: null,
        voucher_number: null,
        date: null,
        bank: null,
        accountType: null,
        accountVal: null,
        accountNum: null,
        notes: null,
      },

      // ========== Fixed_data
      banks: null,
      accountTypes: [
        {
          value: "user",
          name: "شخصي",
        },
        {
          value: "company",
          name: "شركة",
        },
      ],
      currencies: [],
      companies: [],
    };
  },

  computed: {},

  watch: {
    [`searchOptions.search_company`](newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.companies = null;
      } else {
        if (!newVal & (oldVal == "")) {
          this.get_filterd_companies(oldVal);
        } else if ((newVal == "") & (oldVal != "")) {
          this.get_filterd_companies(newVal);
        } else {
          this.get_filterd_companies(newVal);
        }
      }
    },
  },

  methods: {
    // START:: GET DATA
    getAllData() {
      this.$axios({
        method: "GET",
        url: "client/installment/" + this.$route.params.id,
      }).then((res) => {
        this.data.companyName = res.data.data.company;
        this.data.amount = res.data.data.amount;
        this.data.currency = res.data.data.currency;
        this.data.voucher_number = res.data.data.check_number;
        this.selectedFile.name = res.data.data.image;
        this.data.date = res.data.data.date;
        this.data.bank = res.data.data.bank;
        this.data.accountType = res.data.data.account_type;
        this.data.accountNum = res.data.data.account_number;
        this.data.notes = res.data.data.desc;
      });
    },
    // END:: GET DATA
    // get_filterd_companies
    get_filterd_companies(keyword) {
      this.load_companies = true;
      this.$axios({
        method: "GET",
        url: `client/company?keyword=${keyword}`,
      })
        .then((res) => {
          this.companies = res.data.data;
          this.load_companies = false;
        })
        .catch((err) => {
          this.load_companies = false;
        });
    },

    getCurrencies() {
      // START:: GET SAFES
      this.$axios({
        method: "GET",
        url: `client/currency`,
      })
        .then((res) => {
          this.currencies = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET SAFES
    },

    getBanks() {
      // START:: GET BANKS
      this.$axios({
        method: "GET",
        url: `client/bank`,
      })
        .then((res) => {
          this.banks = res.data.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      // END:: GET BANKS
    },
    chooseType() {
      this.data.accountVal = this.data.accountType.value;
      if (this.data.accountVal == "company") {
        this.data.accountNum = this.data.companyName.account_number;
      } else {
        this.data.accountNum = null;
      }
    },
    // START:: HANDLING SELECTED FILE METHOD
    handelSelectedFile(e) {
      this.selectedFile.file = e.target.files[0];
      this.selectedFile.name = e.target.files[0].name;
      this.selectedFile.path = URL.createObjectURL(e.target.files[0]);
    },
    // END:: HANDLING SELECTED FILE METHOD

    // Validate Data
    validateCreateForm() {
      if (!this.btnIsLoading) {
        this.btnIsLoading = true;
        if (!this.data.companyName) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال اسم الشركة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.amount) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال المبلغ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.currency) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال العملة ",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.voucher_number) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال رقم الشيك",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.date) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يرجي إدخال تاريخ الإستحقاق",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.bank) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال البنك",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.accountType) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال نوع الحساب",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else if (!this.data.accountNum) {
          this.$iziToast.error({
            timeout: 2000,
            message: "يجب إدخال رقم الحساب",
            position: "topRight",
          });
          this.btnIsLoading = false;
          return;
        } else {
          this.submitData();
          return;
        }
      }
    },

    // Submit Data
    submitData() {
      const submit_data = {
        company_name: this.data.companyName,
        amount: this.data.amount,
        currency_name: this.data.currency,
        check_number: this.data.voucher_number,
        image: this.selectedFile.file,
        date: this.data.date,
        desc: this.data.notes,
        bank_name: this.data.bank,
        account_type: this.data.accountType,
        account_number: this.data.accountNum,
      };
      if (!this.selectedFile.file) {
        delete submit_data.image;
      }
      // START:: APPEND REQUEST DATA
      this.$axios({
        method: "PUT",
        url: "client/installment/" + this.$route.params.id,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 5000,
            message: "تم التعديل بنجاح",
            position: "topRight",
          });
          this.$router.push("/cach");
          this.btnIsLoading = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "topRight",
          });
          this.btnIsLoading = false;
        });
    },
  },

  created() {
    this.getCurrencies();
    this.getBanks();
    this.getAllData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    // permisions
    var userType = JSON.parse(this.$store.state.usertype) || null;
    if (
      userType.includes("confirm_company_cash") &&
      !userType.includes("company_cash")
    ) {
      this.$router.push("/cach");
    } else if (
      !userType.includes("superadmin") &&
      !userType.includes("company_cash")
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss">
.load_inline {
  width: 30px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  left: 1px;
  position: absolute !important;
  background: transparent !important;
  margin: 0 !important;

  span {
    right: 0 !important;
    left: 0 !important;
    margin: auto !important;
    border-top: 3px solid var(--mainColor-2) !important;
    border-right: 3px solid transparent !important;
  }
}
</style>
